import { createStore } from 'vuex';
import sidebar from '@/store/modules/sidebar';
import dropdown from '@/store/modules/dropdown';
import color from '@/store/modules/color';
import dataTable from '@/store/modules/dataTable';
import timeEntry from '@/store/modules/timeEntry';
import userInformation from '@/store/modules/userInformation';
import auth from '@/store/modules/auth';
import accountApi from '@/store/modules/accountApi';
import categoryApi from '@/store/modules/categoryApi';
import clockApi from '@/store/modules/clockApi';
import companyApi from '@/store/modules/companyApi';
import definitionApi from '@/store/modules/definitionApi';
import paymentApi from '@/store/modules/paymentApi';
import profileApi from '@/store/modules/profileApi';
import timesheetApi from '@/store/modules/timesheetApi';
import settingApi from '@/store/modules/settingApi';
import promotionApi from '@/store/modules/promotionApi';
import reportApi from '@/store/modules/reportApi';
import modal from '@/store/modules/modal';
import downloadApi from '@/store/modules/downloadApi';
import testModule from '@/store/modules/testModule';
import timeSheet from '@/store/modules/timeSheet';
export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    sidebar,
    dropdown,
    color,
    timeEntry,
    userInformation,
    dataTable,
    auth,
    accountApi,
    categoryApi,
    clockApi,
    companyApi,
    definitionApi,
    paymentApi,
    profileApi,
    timesheetApi,
    settingApi,
    promotionApi,
    reportApi,
    modal,
    downloadApi,
    testModule,
    timeSheet,
  },
});
