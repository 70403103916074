import store from "..";

const state = {
  blockBackgroundColor: "#e91e63",
};

const getters = {
  getBlockBackgroundColor(state) {
    return state.blockBackgroundColor;
  },
};

const mutations = { 
  setMutateHandler(state, input) {
    const {data, type } = input
    state[type] = data;
  },
};

const actions = {
  blockBackgroundColorAction({ commit }, data) {
    let input = {
      data: data,
      type: "blockBackgroundColor",
    };
    commit("setMutateHandler", input);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
