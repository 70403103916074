<template>
  <div class="data-table-pagination">
    <!--region Go to page section -->
    <!-- <div class="pagination-search">
				<span>{{ paginationSearchText }}</span>
				<input
					@keyup.enter="setCurrentPage(pageToGo)"
					v-model="pageToGo"
					:max="numberOfPages"
					min="1"
					type="number"
					class="form-control"
				/>
				<button @click="setCurrentPage(pageToGo)" class="btn btn-primary">
					{{ paginationSearchButtonText }}
				</button>
			</div> -->
    <!--endregion -->
    <ul class="pagination">
      <li
        @click="setCurrentPage(previousPage)"
        :class="{ disabled: isFirstPage }"
        class="page-item"
      >
        <span class="page-link">
          {{ previousButtonText }}
        </span>
      </li>
      <li
        v-for="(page, i) in pagination"
        :key="i"
        @click="setCurrentPage(page)"
        :class="{
          active: currentPage === page,
          disabled: page === '...',
        }"
        class="page-item"
      >
        <span class="page-link">
          {{ page }}
        </span>
      </li>
      <li @click="setCurrentPage(nextPage)" :class="{ disabled: isLastPage }" class="page-item">
        <span class="page-link">
          {{ nextButtonText }}
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'DataTablePagination',
  data() {
    return {
      pageToGo: 1,
    };
  },
  methods: {
    setCurrentPage(page) {
      this.$emit('set-page', Number(page));
    },
  },
  props: {
    paginationSearchButtonText: String,
    paginationSearchText: String,
    previousButtonText: String,
    nextButtonText: String,
    isFirstPage: Boolean,
    isLastPage: Boolean,
    numberOfPages: Number,
    previousPage: Number,
    currentPage: Number,
    nextPage: Number,
    pagination: Array,
  },
  watch: {
    currentPage(value) {
      this.pageToGo = value;
    },
    pageToGo(value) {
      if (value > this.numberOfPages) {
        return this.numberOfPages;
      }
      if (value < 1) {
        return 1;
      }
      return value;
    },
  },
};
</script>
<style lang="scss">
.data-table-pagination {
  margin-left: auto;
  grid-area: pagination;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #86889e;

  & > :first-child {
    margin-right: 10px;
  }
  & > * {
    margin-bottom: 10px !important;
  }
  .pagination {
    margin: 0 !important;
    // padding: 6px;
    overflow: auto;
    background: #f3f3f5;
    border: 1px solid #c5c6d0;
    border-radius: 12px;
    li {
      span {
        padding: 13px;
      }
    }
  }
  .pagination-search {
    display: flex;
    align-items: center;
    white-space: nowrap;
    * {
      margin-right: 5px;
    }
    input {
      width: 70px;
    }
  }
  .page-item {
    user-select: none;
  }
  .page-link {
    cursor: pointer;
  }
}
</style>
