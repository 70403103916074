import DefinitionService from '@/services/definition.service';
import ClockService from '@/services/clock.service';
import ProfileService from '@/services/profile.service';

const API_URL = process.env.VUE_APP_API_URL;
import { reactive } from 'vue';
const state = reactive({
  Employees: [],
  Invitations: [],
  ActiveAnnuals: [],
  PassedAnnuals: [],
  Members: [],
  Annual: null,
  Employee: null,
  EmployeeTitles: [],
  ManagerTitles: [],
  Teams: [],
  Tags: [],
});
const getters = {
  getDefinitionEmployees(state) {
    return state.Employees;
  },
  getDefinitionInvitations(state) {
    return state.Invitations;
  },
  getDefinitionActiveAnnuals(state) {
    return state.ActiveAnnuals;
  },
  getDefinitionPassedAnnuals(state) {
    return state.PassedAnnuals;
  },
  getDefinitionMembers(state) {
    return state.Members;
  },
  getDefinitionAnnual(state) {
    return state.Annual;
  },
  getDefinitionEmployee(state) {
    return state.Employee;
  },
  getDefinitionEmployeeTitles(state) {
    return state.EmployeeTitles;
  },
  getDefinitionManagerTitles(state) {
    return state.ManagerTitles;
  },
  getDefinitionTeams(state) {
    return state.Teams;
  },
  getDefinitionTags(state) {
    return state.Tags;
  },
};
const mutations = {
  getEmployees(state, payload) {
    state.Employees = payload.Members;
    state.Invitations = payload.Invitations;
    state.EmployeeTitles = payload.EmployeeTitles.map((p) => ({ id: p.ID, text: p.Name }));
    state.ManagerTitles = payload.ManagerTitles.map((p) => ({ id: p.ID, text: p.Name }));
    state.Teams = payload.Teams.map((p) => ({ id: p.ID, text: p.Name }));
  },
  getAnnuals(state, payload) {
    state.ActiveAnnuals = payload.ActiveAnnuals;
    state.PassedAnnuals = payload.PassedAnnuals;
    state.Members = payload.Members.map((p) => ({ id: p.ID, text: p.Name }));
  },
  saveAnnual(state, payload) {
    state.ActiveAnnuals = state.ActiveAnnuals.filter((p) => p.ID !== payload.DTO.ID);
    state.PassedAnnuals = state.PassedAnnuals.filter((p) => p.ID !== payload.DTO.ID);
    if (payload.DTO.IsActive) state.ActiveAnnuals.push(payload.DTO);
    else state.PassedAnnuals.push(payload.DTO);
  },
  deleteEmployee(state, payload) {
    state.Employees = state.Employees.filter((p) => p.ID !== payload.ID);
  },
  deleteAnnual(state, payload) {
    state.ActiveAnnuals = state.ActiveAnnuals.filter((p) => p.ID !== payload.ID);
    state.PassedAnnuals = state.PassedAnnuals.filter((p) => p.ID !== payload.ID);
  },
  getAnnual(state, payload) {
    state.Annual = payload.DTO;
  },
  setAnnual(state, payload) {
    state.Annual = payload;
  },
  setEmployee(state, payload) {
    state.Employee = payload;
  },
  cancelEmployeeInvitation(state, payload) {
    state.Invitations = state.Invitations.filter((p) => p.Email != payload.Email);
  },
  saveEmployeeInvitation(state, payload) {
    let emails = payload.DTO.map((p) => p.EmailAddress);
    let invitations = payload.DTO.map((p) => ({ ID: p.Id, Email: p.EmailAddress }));
    state.Invitations = state.Invitations.filter((p) => !emails.includes(p.Email));
    state.Invitations = [...state.Invitations, ...invitations];
  },

  getEmployee(state, payload) {
    state.Employee = payload.DTO;
  },
  saveEmployee(state, payload) {
    const index = state.Employees.findIndex((p) => p.ID === payload.DTO.ID);
    if (index !== -1) {
      state.Employees.splice(index, 1, payload.DTO);
    } else {
      state.Employees.push(payload.DTO);
    }
  },
  saveEmployeeImage(state, payload) {
    var employee = state.Employees.find((p) => p.ID === payload.DTO.MemberId);
    employee.ImageUrl = payload.DTO.ImageUrl;
  },
};
const actions = {
  getEmployees(context) {
    DefinitionService.getEmployees()
      .then((res) => {
        context.commit('getEmployees', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  getAnnuals(context) {
    DefinitionService.getAnnuals()
      .then((res) => {
        context.commit('getAnnuals', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  saveAnnual(context, payload) {
    let request = payload;
    DefinitionService.saveAnnual(request)
      .then((res) => {
        context.commit('saveAnnual', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  deleteEmployee(context, payload) {
    let request = payload;
    DefinitionService.deleteEmployee(request)
      .then((res) => {
        context.commit('deleteEmployee', request);
      })
      .catch((err) => console.log('catch ', err));
  },
  deleteAnnual(context, payload) {
    let request = payload;
    DefinitionService.deleteAnnual(request)
      .then((res) => {
        context.commit('deleteAnnual', request);
      })
      .catch((err) => console.log('catch ', err));
  },
  getAnnual(context, payload) {
    let request = payload;
    DefinitionService.getAnnual(request)
      .then((res) => {
        context.commit('getAnnual', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  setAnnual(context, payload) {
    context.commit('setAnnual', payload);
  },
  getEmployee(context, payload) {
    let request = payload;
    DefinitionService.getEmployee(request)
      .then((res) => {
        context.commit('getEmployee', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  setEmployee(context, payload) {
    context.commit('setEmployee', payload);
  },

  cancelEmployeeInvitation(context, payload) {
    context.commit('cancelEmployeeInvitation', payload);
  },
  saveEmployeeInvitation(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      ClockService.saveInvitation(request)
        .then((res) => {
          context.commit('saveEmployeeInvitation', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  saveEmployee(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      DefinitionService.saveEmployee(request)
        .then((res) => {
          context.commit('saveEmployee', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  saveEmployeeImage(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      ProfileService.saveEmployeeImage(request)
        .then((res) => {
          context.commit('saveEmployeeImage', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
