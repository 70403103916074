const state = {
  hasPremium: true,
};

const getters = {
  getHasPremium(state) {
    return state.hasPremium;
  },
};

const mutations = { 
  setMutateHandler(state, input) {
    let data = input.data;
    let type = input.type;
    state[type] = data;
  },
};

const actions = {
  getHasPremiumInformationAction({ commit }, data) {
    let input = {
      data: data,
      type: "hasPremium",
    };
    commit("setMutateHandler", input);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
