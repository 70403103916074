<template>
  <CustomModal
    :acceptButtonText="t('ConfirmModal.AcceptButtonText')"
    :cancelButtonText="t('ConfirmModal.CancelButtonText')"
    :title="t('ConfirmModal.HeaderTitle')"
    @submit="onSave"
    @onCancel="onCancel"
    hasAcceptButton
    hasCancelButton
    getterName="getConfirmModalState"
  >
    <span>{{ titlep }}</span>
  </CustomModal>
</template>
<script setup>
import CustomModal from '@/components/modal/CustomModal.vue';

import { ref, defineProps, computed, defineEmits } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
const { t } = useI18n({ useScope: 'global' });
const store = useStore();
const emit = defineEmits(['cancel', 'accept']);

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
});

const titlep = computed(() => (props.title ? props.title : t('ConfirmModal.DefaultModalText')));

const onCancel = () => {
  store.dispatch('setModalStateAction', {
    modal: 'confirmModal',
    value: false,
  });
  store.dispatch('setDialogStateAction', false);
  emit('cancel');
};
const onSave = () => {
  store.dispatch('setModalStateAction', {
    modal: 'confirmModal',
    value: false,
  });
  store.dispatch('setDialogStateAction', false);
  emit('accept');
};
</script>
<style lang="scss" scoped></style>
