<template>
  <div class="data-table-per-page">
    <!-- <span>{{ textBeforeOptions }}</span>  Show-->
    <select @input="$emit('set-per-page')" :value="currentPerPage">
      <option v-for="(length, i) in perPageSizes" :key="i" :value="length">
        {{ length }}
      </option>
    </select>
    <!-- <span>{{ textAfterOptions }}</span> entries-->
  </div>
</template>
<script>
export default {
  name: 'DataTablePerPage',
  computed: {
    textBeforeOptions() {
      return this.perPageText.split(':entries')[0].trim();
    },
    textAfterOptions() {
      return (this.perPageText.split(':entries')[1] || '').trim();
    },
  },
  methods: {
    stringNotEmpty(string) {
      return string !== '';
    },
  },
  props: {
    perPageText: {
      type: String,
      required: true,
    },
    currentPerPage: {
      type: Number,
      required: true,
    },
    perPageSizes: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.data-table-per-page {
  display: flex;
  align-items: center;
  grid-area: perPage;
  justify-content: flex-start;

  select {
    margin: 0 5px;
    height: auto !important;
    padding: 12px;
    line-height: 1;
    color: #86889e;
    background: #fff;
    border-radius: 0.25rem;
    display: inline-block;

    border: 1px solid #c5c6d0;
    border-radius: 13px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #86889e;
  }
}
</style>
