import { createRouter, createWebHistory } from 'vue-router';
import Vue from 'vue';
import i18n from '../i18n';

const { t } = i18n.global;
const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('@/views/Wizard/Login/Login.vue'),
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/forgot',
    name: 'ForgotPassword',
    component: () => import('@/views/Wizard/ForgotPassword/ForgotPassword.vue'),
    meta: {
      title: 'Forgot Password',
    },
  },
  {
    path: '/reset',
    name: 'ResetPassword',
    component: () => import('@/views/Wizard/ResetPassword/ResetPassword.vue'),
    meta: {
      title: 'Reset Password',
    },
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('@/views/Wizard/SignUp/SignUp.vue'),
    meta: {
      title: 'Sign Up',
    },
  },
  {
    path: '/first-download',
    name: 'first-download',
    component: () => import('@/views/Wizard/WizardDownload/WizardDownload.vue'),
    meta: {
      title: 'Download App',
    },
  },
  {
    path: '/download/:id',
    name: 'download-invitation',
    component: () => import('@/views/Wizard/InviteDownload/InviteDownload.vue'),
    meta: {
      title: 'Download App',
    },
  },
  {
    path: '/confirm',
    name: 'Confirm Email',
    component: () => import('@/views/Wizard/Confirm/ConfirmEmail.vue'),
    meta: {
      title: 'Confirm Email',
    },
  },
  {
    path: '/',
    name: 'LayoutWrapper',
    component: () => import('../layouts/LayoutMain.vue'),
    children: [
      {
        path: '/setting/company',
        name: 'setting-company',
        component: () => import('@/views/AdminCompany/AdminCompany.vue'),
        meta: {
          title: 'Titles.CompanyTitle',
        },
        children: [],
      },
      {
        path: '/clock/section',
        name: 'clock-section',
        component: () => import('@/views/WorktimeUsage/SectionWorktime.vue'),
        meta: {
          title: 'Titles.ClockTitle',
        },
        children: [
          {
            path: '/clock/section/productivity-team',
            name: 'section-productivity-team',
            component: () => import('@/views/WorktimeUsage/_components/productivity/Team.vue'),
          },
          {
            path: '/clock/section/wellbeing-team',
            name: 'section-wellbeing-team',
            component: () => import('@/views/WorktimeUsage/_components/wellbeing/Team.vue'),
          },
          {
            path: '/clock/section/distribution',
            name: 'section-distribution',
            component: () =>
              import('@/views/WorktimeUsage/_components/distribution/Distribution.vue'),
          },
          {
            path: '/clock/section/productivity-individual',
            name: 'section-productivity-individual',
            component: () =>
              import('@/views/WorktimeUsage/_components/productivity/Individuals.vue'),
          },
          {
            path: '/clock/section/wellbeing-individual',
            name: 'section-wellbeing-individual',
            component: () => import('@/views/WorktimeUsage/_components/wellbeing/Individuals.vue'),
          },
          {
            path: '/clock/section/productivity-graph',
            name: 'section-productivity-graph',
            component: () => import('@/views/WorktimeUsage/_components/productivity/Graph.vue'),
          },
          {
            path: '/clock/section/wellbeing-graph',
            name: 'section-wellbeing-graph',
            component: () => import('@/views/WorktimeUsage/_components/wellbeing/Graph.vue'),
          },
        ],
      },
      {
        path: '/clock/section/:id',
        name: 'clock-section-id',
        component: () => import('@/views/WorktimeUsage/SectionWorktime.vue'),
        meta: {
          title: 'Teams',
        },
        children: [
          {
            path: '/clock/section/:id',
            name: 'section-id-default',
            component: () => import('@/views/WorktimeUsage/_components/productivity/Team.vue'),
          },
          {
            path: '/clock/section/:id/productivity-team',
            name: 'section-id-productivity-team',
            component: () => import('@/views/WorktimeUsage/_components/productivity/Team.vue'),
          },
          {
            path: '/clock/section/:id/wellbeing-team',
            name: 'section-id-wellbeing-team',
            component: () => import('@/views/WorktimeUsage/_components/wellbeing/Team.vue'),
          },
          {
            path: '/clock/section/:id/distribution',
            name: 'section-id-distribution',
            component: () =>
              import('@/views/WorktimeUsage/_components/distribution/Distribution.vue'),
          },
          {
            path: '/clock/section/:id/productivity-individual',
            name: 'section-id-productivity-individual',
            component: () =>
              import('@/views/WorktimeUsage/_components/productivity/Individuals.vue'),
          },
          {
            path: '/clock/section/:id/wellbeing-individual',
            name: 'section-id-wellbeing-individual',
            component: () => import('@/views/WorktimeUsage/_components/wellbeing/Individuals.vue'),
          },
          {
            path: '/clock/section/:id/productivity-graph',
            name: 'section-id-productivity-graph',
            component: () => import('@/views/WorktimeUsage/_components/productivity/Graph.vue'),
          },
          {
            path: '/clock/section/:id/wellbeing-graph',
            name: 'section-id-wellbeing-graph',
            component: () => import('@/views/WorktimeUsage/_components/wellbeing/Graph.vue'),
          },
        ],
      },
      {
        path: '/clock/employee/:id',
        name: 'clock-employee',
        component: () => import('@/views/WorktimeUsage/EmployeeWorktime.vue'),
        meta: {
          title: 'Titles.ClockTitle',
        },
        children: [
          {
            path: '/clock/employee/:id/wellbeing',
            name: 'employee-wellbeing',
            component: () => import('@/views/WorktimeUsage/_components/wellbeing/Employee.vue'),
          },
          {
            path: '/clock/employee/:id/distribution',
            name: 'employee-distribution',
            component: () =>
              import('@/views/WorktimeUsage/_components/distribution/Distribution.vue'),
          },
          {
            path: '/clock/employee/:id/meetings',
            name: 'employee-meetings',
            component: () => import('@/views/WorktimeUsage/_components/meetings/Meetings.vue'),
          },
          {
            path: '/clock/employee/:id/webhistory',
            name: 'employee-webhistory',
            component: () => import('@/views/WorktimeUsage/_components/webHistory/WebHistory.vue'),
          },
          {
            path: '/clock/employee/:id/manual',
            name: 'employee-manual',
            component: () =>
              import('@/views/WorktimeUsage/_components/manualEntries/ManuelEntries.vue'),
          },
          {
            path: '/clock/employee/:id/productivity-graph',
            name: 'employee-productivity-graph',
            component: () => import('@/views/WorktimeUsage/_components/productivity/Graph.vue'),
          },
          {
            path: '/clock/employee/:id/wellbeing-graph',
            name: 'employee-wellbeing-graph',
            component: () => import('@/views/WorktimeUsage/_components/wellbeing/Graph.vue'),
          },
        ],
      },
      {
        path: '/timesheet/show-both',
        name: 'Timesheet',
        component: () => import('@/views/TimeSheet/TimeSheet.vue'),
        meta: {
          title: 'Titles.TimesheetTitle',
        },
        children: [
          {
            path: '',
            name: 'ShowBoth',
            component: () =>
              import('@/views/TimeSheet/_components/timeSheetContent/timeSheetTabs/ShowBoth.vue'),
          },
          {
            path: '/timesheet/time-entries',
            name: 'Time Entries',
            component: () =>
              import(
                '@/views/TimeSheet/_components/timeSheetContent/timeSheetTabs/TimeEntries.vue'
              ),
          },
          {
            path: '/timesheet/time-history',
            name: 'Time history',
            component: () =>
              import(
                '@/views/TimeSheet/_components/timeSheetContent/timeSheetTabs/TimeHistory.vue'
              ),
          },
        ],
      },
      {
        path: '/timesheet/time-management',
        name: 'Task Management',
        component: () => import('@/views/TimeManagement/TimeManagement.vue'),
        meta: {
          title: 'Titles.TimeManagementTitle',
        },
        children: [],
      },
      {
        path: '/download-app',
        name: 'DownloadApp',
        component: () => import('@/views/DownloadApp/DownloadApp.vue'),
        meta: {
          title: 'Titles.DowloadTitle',
        },
      },
      {
        path: '/promotion',
        name: 'promotion',
        component: () => import('@/views/Promotion/Promotion.vue'),
        meta: {
          title: 'Titles.PromoteTitle',
        },
      },
      {
        path: '/test',
        name: 'test',
        component: () => import('@/views/test/test.vue'),
        meta: {
          title: 'table test',
        },
        // children: [
        //   {
        //     path: '/test/2',
        //     name: 'test2',
        //     component: () => import('@/views/test.vue'),
        //     meta: {
        //       title: 'table test2',
        //     },
        //   },
        // ],
      },
      {
        path: '/test2',
        name: 'test2',
        component: () => import('@/views/test2/test2.vue'),
        meta: {
          title: 'table test2',
        },
      },
      {
        path: '/test3',
        name: 'test3',
        component: () => import('@/views/test3/test3.vue'),
        meta: {
          title: 'table test3',
        },
      },
      {
        path: '/classification/webaddresses',
        name: 'classification',
        component: () => import('@/views/Classification/Classification.vue'),
        meta: {
          title: 'Titles.ClassificationTitle',
        },
        children: [
          {
            path: '',
            name: 'classification-webaddresses',
            component: () => import('@/views/Classification/_components/WebAddresses.vue'),
            meta: {
              title: 'Titles.WebAddressTitle',
            },
          },
          {
            path: '/classification/applications',
            name: 'classification-applications',
            component: () => import('@/views/Classification/_components/Applications.vue'),
            meta: {
              title: 'Titles.ApplicationTitle',
            },
          },
        ],
      },
      {
        path: '/definition/employees',
        name: 'Definition-Productivity',
        component: () => import('@/views/Definition/Employees.vue'),
        meta: {
          title: 'Titles.EmployeeTitle',
        },
      },
      {
        path: '/definition/annuals',
        name: 'Definition-Annuals',
        component: () => import('@/views/Definition/Annuals.vue'),
        meta: {
          title: 'Titles.AnnualLeaveTitle',
        },
        children: [
          {
            path: '',
            name: 'ActiveAnnuals',
            component: () => import('@/views/Definition/_components/ActiveAnnuals.vue'),
            meta: {
              title: 'Titles.ActiveAnnualTitle',
            },
          },
          {
            path: '/definition/annuals/ended-annuals',
            name: 'EndedAnnuals',
            component: () => import('@/views/Definition/_components/EndedAnnuals.vue'),
            meta: {
              title: 'Titles.EndedAnnualTitle',
            },
          },
        ],
      },
      {
        path: '/company/holidays',
        name: 'Company-Holidays',
        component: () => import('@/views/Company/Holidays.vue'),
        meta: {
          title: 'Titles.HolidayTitle',
        },
      },
      {
        path: '/company/organization-chart',
        name: 'Company-OrganizationChart',
        component: () => import('@/views/Company/OrganizationChart.vue'),
        meta: {
          title: 'Titles.OrganizationTitle',
        },
      },
      {
        path: '/company/working-hours',
        name: 'Company-WorkingHours',
        component: () => import('@/views/Company/WorkingHours.vue'),
        meta: {
          title: 'Titles.WorkingHourTitle',
        },
      },
      {
        path: '/report/elastic',
        name: 'report',
        component: () => import('@/views/Report/Reports.vue'),
        meta: {
          title: 'Titles.ReportTitle',
        },
        children: [
          {
            path: '',
            name: 'report-elastic',
            component: () => import('@/views/Report/ElasticReports.vue'),
            meta: {
              title: 'Titles.ElasticReportTitle',
            },
          },
          {
            path: '/report/default',
            name: 'report-default',
            component: () => import('@/views/Report/DefaultReports.vue'),
            meta: {
              title: 'Titles.DefaultReportTitle',
            },
          },
        ],
      },
      {
        path: '/payment/billing',
        name: 'payment-billing',
        component: () => import('@/views/Payment/Billing.vue'),
        meta: {
          title: 'Titles.BillingTitle',
        },
      },
      {
        path: '/payment/subscribe',
        name: 'payment-subscribe',
        component: () => import('@/views/Payment/Subscribe.vue'),
        meta: {
          title: 'Titles.SubscribeTitle',
        },
      },
      {
        path: '/payment/success',
        name: 'payment-success',
        component: () => import('@/views/Payment/PaymentSuccess.vue'),
        meta: {
          title: 'Titles.PaymentSuccessTitle',
        },
      },
      {
        path: '/payment/failed',
        name: 'payment-failed',
        component: () => import('@/views/Payment/PaymentFailed.vue'),
        meta: {
          title: 'Titles.PaymentFailTitle',
        },
      },
      {
        path: '/settings/advanced',
        name: 'Settings-Advanced',
        component: () => import('@/views/Settings/AdvancedSettings.vue'),
        meta: {
          title: 'Titles.AdvancedTitle',
        },
      },
      {
        path: '/settings/permissions',
        name: 'Settings-Permissions',
        component: () => import('@/views/Settings/Permissions.vue'),
        meta: {
          title: 'Titles.PermissionTitle',
        },
      },
      {
        path: '/settings/integrations',
        name: 'Settings-Integrations',
        component: () => import('@/views/Settings/Integrations.vue'),
        meta: {
          title: 'Titles.IntegrationTitle',
        },
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/views/Profile/Profile'),
        meta: {
          title: 'Titles.ProfileTitle',
        },
        children: [
          {
            path: '/profile',
            name: 'profile-basic',
            component: () => import('@/views/Profile/_components/Tabs/BasicInfo.vue'),
          },
          {
            path: '/profile/license',
            name: 'profile-license',
            component: () => import('@/views/Profile/_components/Tabs/LicenseInfo.vue'),
          },
          {
            path: '/profile/communication',
            name: 'profile-communication',
            component: () => import('@/views/Profile/_components/Tabs/Communication.vue'),
          },
          {
            path: '/profile/integrations',
            name: 'Profile-integrations',
            component: () => import('@/views/Profile/_components/Tabs/Integrations.vue'),
          },
          {
            path: '/profile/password',
            name: 'profile-password',
            component: () => import('@/views/Profile/_components/Tabs/Password.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/server-not-found',
    name: '500',
    component: () => import('@/views/ErrorPages/ServerError.vue'),
  },
  {
    path: '/page-not-found',
    name: '404',
    component: () => import('@/views/ErrorPages/NotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const DEFAULT_TITLE = 'FlexyTime';
router.beforeEach((to, from, next) => {
  const publicPages = [
    '/',
    '/signup',
    '/forgot',
    '/reset',
    '/first-download',
    '/confirm',
    '/download/:id',
  ];
  let authRequired = true;
  for (let index = 0; index < publicPages.length; index++) {
    const page = publicPages[index];
    if (!to.path.includes(page)) {
      authRequired = false;
      break;
    }
  }
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    next('/');
  } else {
    document.title = t(to.meta.title) || DEFAULT_TITLE;
    next();
  }
});

export default router;
