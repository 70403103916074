import ClockService from '@/services/clock.service';
import moment from 'moment';
const API_URL = process.env.VUE_APP_API_URL;
import { reactive } from 'vue';
const state = reactive({
  Card: {
    Name: null,
    Since: null,
    OnLeave: false,
    LeaveType: null,
  },
  Summary: [],
  Breadcrumb: [],
  Alerts: {
    IsLicenseDanger: false,
    IsCardError: false,
    CardError: '',
    IsLicenseWarning: false,
    ExpireDays: 0,
    IsConfirmation: true,
    IsDownloaded: true,
    IsWaitingData: false,
  },
  Teamset: {
    IsTeam: true,
    Teams: [],
    Members: [],
  },
  Individuals: [],
  Invitations: [],
  Manuals: [],
  Manual: null,
  Query: {
    Perspective: 0,
    Interval: '',
  },
  EmployeeQuery: {},
  Graphs: {
    Summary: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: ['#f87979', 'orange', 'blue'],
        },
      ],
    },
    WellBeings: [],
  },
  WellBeings: [],
  Calendar: [],
});
const getters = {
  getClockCard(state) {
    return state.Card;
  },
  getClockQuery(state) {
    return state.Query;
  },
  getClockSummary(state) {
    return state.Summary;
  },
  getClockBreadcrumb(state) {
    return state.Breadcrumb;
  },
  getClockAlert(state) {
    return state.Alerts;
  },
  getClockDistribution(state) {
    return state.Distributions;
  },
  getClockTeamset(state) {
    return state.Teamset;
  },
  getClockIndividuals(state) {
    return state.Individuals;
  },
  getClockWebClocks(state) {
    return state.WebClocks;
  },
  getClockManuals(state) {
    return state.Manuals;
  },
  getClockManual(state) {
    return state.Manual;
  },
  getClockWellBeings(state) {
    return state.WellBeings;
  },
  getClockGraphs(state) {
    return state.Graphs;
  },
  getClockCalendar(state) {
    return state.Calendar;
  },
  getClockInvitations(state) {
    return state.Invitations;
  },
  getClockDownloadKey(state) {
    return state.DownloadKey;
  },
  getClockEmployeeQuery(state) {
    return state.EmployeeQuery;
  },
};
const mutations = {
  getAlerts(state, payload) {
    state.Alerts = payload;
  },
  getClockSection(state, payload) {
    state.DownloadKey = payload.DownloadKey;
    state.Summary = payload.Summary;
    state.WellBeings = payload.WellBeings;
    state.Breadcrumb = payload.Breadcrumb;
    state.Distributions = payload.Distributions;
    state.Graphs = payload.Graphs;
    state.Card = payload.Card;
    state.Teamset = payload.Teamset;
    state.Individuals = payload.Individuals;
    state.Invitations = payload.Invitations;
  },
  setClockEmployeeQuery(state, payload) {
    state.EmployeeQuery = payload;
  },
  getClockEmployee(state, payload) {
    state.DownloadKey = payload.DownloadKey;
    state.Card.Email = payload.Card.Email;
    state.Card.Name = payload.Card.Name;
    state.Card.Since = payload.Model.Employee.since;
    state.Card.OnLeave = payload.Model.Employee.HasAnnual;
    state.Card.LeaveType = payload.Model.Employee.AnnualType;
    state.Card.ImageUrl = payload.Model.Employee.imageurl;
    const availability = ['offline', 'idle', 'online'];
    state.Card.Availability = availability[payload.Model.Employee.Availability];
    const statisticTypes = ['', 'unclassified', 'leisure', 'meeting', 'work'];
    const wellbeingTypes = [
      'automation',
      'balanced',
      'distract',
      'fragmented',
      'massemail',
      'nocturnal',
      'overloaded',
      'overmeeting',
      'overtime',
      'uninterrupted',
    ];

    state.WellBeings = payload.Model.WellBeings.map((p, index) => ({
      Name: p.Name,
      Type: wellbeingTypes[p.Type],
      Notification: p.Notification,
      text: p.Name,
      icon: `fl-${wellbeingTypes[p.Type]}`,
      isActive: index == 0,
      id: wellbeingTypes[p.Type],
    }));

    state.Calendar = payload.Model.Calendar.events.map((p, index) => ({
      key: index,
      customData: {
        title: p.title,
        class: 'red-color text-wrapper',
      },
      dates: moment(p.start).toDate(),
    }));

    state.Manuals = payload.Model.Manuals.map((p) => ({
      ID: p.ID,
      statisticType: statisticTypes[p.Domain],
      date: p.Date,
      Start: { statisticType: 'starttime', time: p.Start },
      End: { statisticType: 'endtime', time: p.End },
      description: p.Description,
    }));
    state.WebClocks = payload.Model.WebClocks.map((p) => ({
      title: p.Name,
      time: p.Spent,
      statisticType: statisticTypes[p.Type],
      Applications: p.WebClocks.map((q) => ({
        date: q.Date,
        url: q.Url,
        subject: q.TopicName,
        spendTime: q.Spent,
        Domain: q.Domain,
      })),
    }));
    let graphUnit = { Dakika: 'minute', Saat: 'hour', Gün: 'day', Adet: 'item' };

    state.Graphs.WellBeings = payload.Model.WellBeings.map((p, index) => ({
      Type: wellbeingTypes[p.Type],
      datasets: p.Graph.datasets.map((q) => ({
        label: q.label,
        data: q.data,
        fill: false,
        backgroundColor: q.borderColor,
        borderColor: q.backgroundColor,
        borderWidth: 2,
        stack: '',
      })),
      labels: p.Graph.labels,
      Unit: graphUnit[p.Graph.Unit],
    }));
    state.Graphs.Summary.datasets = payload.Model.Graph.datasets.map((p) => ({
      label: p.label,
      data: p.data,
      fill: false,
      backgroundColor: p.borderColor,
      borderColor: p.backgroundColor,
      borderWidth: 2,
      stack: 'bar-stacked',
    }));
    state.Graphs.Summary.labels = payload.Model.Graph.labels;

    state.Graphs.Summary.Unit = graphUnit[payload.Model.Graph.Unit];

    state.Distributions = payload.Model.Allocations.map((p) => ({
      id: p.id,
      statisticType: p.name.toLowerCase(),
      time: p.spend,
      Applications: p.Allocations.map((q) => ({
        imgPath: 'fl-default-app-icon',
        title: q.name,
        time: q.spend,
      })),
      Chart: p.Chart2.data.map((q, index) => {
        const sum = p.Chart2.data.reduce((accumulator, value) => {
          return accumulator + value;
        }, 0);
        return {
          label: p.Chart2.labels[index],
          value: (100 * p.Chart2.data[index]) / sum,
        };
      }),
    }));
    state.Summary = payload.Model.Summary.Allocations.map((p) => ({
      id: p.id,
      statisticType: p.name.toLowerCase(),
      time: p.spend,
    }));

    var starttime = {
      id: 'starttime',
      statisticType: 'starttime',
      time: payload.Model.Summary.Start,
    };
    var endtime = {
      id: 'endtime',
      statisticType: 'endtime',
      time: payload.Model.Summary.End,
    };
    state.Summary.push(starttime, endtime);
    state.Breadcrumb = payload.Breadcrumbs.map((p, index) => ({
      id: p.Url.split('/').slice(-1)[0],
      title: p.Name,
      path: p.Url.toLowerCase().replace('~', ''),
      isLastElement: index + 1 == payload.Breadcrumbs.length,
    }));
  },
  saveManual(state, payload) {
    state.Manuals = state.Manuals.filter((p) => p.ID !== payload.DTO.ID);
    const statisticTypes = ['', 'unclassified', 'leisure', 'meeting', 'work'];
    var request = {
      ID: payload.DTO.ID,
      statisticType: statisticTypes[payload.DTO.Domain],
      date: payload.DTO.Date,
      Start: { statisticType: 'starttime', time: payload.DTO.Start },
      End: { statisticType: 'endtime', time: payload.DTO.End },
      description: payload.DTO.Description,
    };
    state.Manuals.push(request);
  },
  getManual(state, payload) {
    state.Manual = payload.DTO;
  },
  setManual(state, payload) {
    state.Manual = payload;
  },
  setQueryPerspective(state, payload) {
    state.Query.Perspective = payload;
  },
  setQueryInterval(state, payload) {
    state.Query.Interval = payload;
  },
  deleteManual(state, payload) {},
  resendInvitation(state, payload) {},
  cancelInvitation(state, payload) {
    state.Invitations = state.Invitations.filter((p) => p.Email != payload.Email);
  },
  saveInvitation(state, payload) {
    let emails = payload.DTO.map((p) => p.EmailAddress);
    let invitations = payload.DTO.map((p) => ({ ID: p.Id, Email: p.EmailAddress }));
    state.Invitations = state.Invitations.filter((p) => !emails.includes(p.Email));
    state.Invitations = [...state.Invitations, ...invitations];
  },
  setInvitation(state, payload) {
    state.Invitation = payload;
  },
  saveWebClock(state, payload) {
    const statisticTypes = ['', 'unclassified', 'leisure', 'meeting', 'work'];

    let cardold = state.WebClocks.find((p) => {
      return p.Applications.some((q) => {
        return q.url === payload.HostName;
      });
    });

    const item = cardold.Applications.find((p) => p.url === payload.HostName);
    item.Domain = payload.Domain;
    const cardnew = state.WebClocks.find((p) => p.statisticType == statisticTypes[payload.Domain]);
    cardnew.Applications.push(item);
    cardold.Applications = cardold.Applications.filter((p) => p.url != payload.HostName);
  },
};
const actions = {
  getAlerts(context, payload) {
    ClockService.getAlerts()
      .then((res) => {
        context.commit('getAlerts', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  getClockSection(context, payload) {
    let request = payload;
    ClockService.getSection(request)
      .then((res) => {
        context.commit('getClockSection', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  getClockEmployee(context, payload) {
    let request = payload;
    context.commit('setClockEmployeeQuery', request);
    ClockService.getEmployee(request)
      .then((res) => {
        context.commit('getClockEmployee', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  saveManual(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      ClockService.saveManual(request)
        .then((res) => {
          context.commit('saveManual', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  getManual(context, payload) {
    let request = payload;
    ClockService.getManual(request)
      .then((res) => {
        context.commit('getManual', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  setManual(context, payload) {
    context.commit('setManual', payload);
  },
  deleteManual(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      ClockService.deleteManual(request)
        .then((res) => {
          context.commit('deleteManual', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  saveWebClock(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      ClockService.saveWebClock(request)
        .then((res) => {
          context.commit('saveWebClock', request);
          return resolve(request);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  setQueryPerspective(context, payload) {
    context.commit('setQueryPerspective', payload);
  },
  setQueryInterval(context, payload) {
    context.commit('setQueryInterval', payload);
  },
  resendInvitation(context, payload) {
    let request = payload;
    ClockService.resendInvitation(request)
      .then((res) => {
        context.commit('resendInvitation', payload);
      })
      .catch((err) => console.log('catch ', err));
  },
  cancelInvitation(context, payload) {
    let request = payload;
    ClockService.cancelInvitation(request)
      .then((res) => {
        context.commit('cancelInvitation', payload);
      })
      .catch((err) => console.log('catch ', err));
  },
  saveInvitation(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      ClockService.saveInvitation(request)
        .then((res) => {
          context.commit('saveInvitation', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  setInvitation(context, payload) {
    context.commit('setInvitation', payload);
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
