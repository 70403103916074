const state = {
  isSidebarOpen: false,
  sidebarWidth: '240px'
};

const getters = {
  getSideBarStatus(state) {
    return state.isSidebarOpen;
  },
  getSideBarWidth(state) {
    return state.sidebarWidth;
  },
};

const mutations = { 
  setMutateHandler(state, input) {
    let data = input.data;
    let type = input.type;
    state[type] = data;
  },
  setSidebarWidth(state) {
    if(!state.isSidebarOpen){
      state.sidebarWidth = '65px';
    }
  }
};

const actions = {
  toggleSidebarAction({ commit }, data) {
    let input = {
      data: data,
      type: "isSidebarOpen",
    };
    commit("setMutateHandler", input);
    commit('setSidebarWidth');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
