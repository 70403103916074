<template>
  <div class="data-table-info">
    {{ entriesInfoText }}
  </div>
</template>
<script>
export default {
  name: 'DataTableEntriesInfo',
  props: {
    entriesInfoText: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.data-table-info {
  grid-area: info;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 165px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: #86889e;
}
</style>
