import axios from 'axios';
import AuthHeader from './auth-header';
const { authHeader } = AuthHeader();
const API_URL = process.env.VUE_APP_API_URL;

class DefinitionService {
  getEmployee(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/definition/employee`, request, { headers: header });
  }
  saveEmployee(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/definition/employee/save`, request, {
      headers: header,
    });
  }
  getAnnual(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/definition/annual`, request, { headers: header });
  }
  deleteAnnual(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/definition/annual/delete`, request, {
      headers: header,
    });
  }
  deleteEmployee(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/definition/employee/delete`, request, {
      headers: header,
    });
  }
  saveAnnual(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/definition/annual/save`, request, {
      headers: header,
    });
  }
  getAnnuals() {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/definition/annuals`, { headers: header });
  }
  getEmployees() {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/definition/employees`, { headers: header });
  }
}
export default new DefinitionService();
