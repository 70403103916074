import axios from 'axios';
import AuthHeader from './auth-header';
const { authHeader } = AuthHeader();
const API_URL = process.env.VUE_APP_API_URL;

class CompanyService {
  saveHoliday(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/company/holiday/save`, request, { headers: header });
  }
  deleteHoliday(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/company/holiday/delete`, request, {
      headers: header,
    });
  }
  getHoliday(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/company/holiday`, request, { headers: header });
  }
  getHolidays() {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/company/holidays`, { headers: header });
  }
  getWorkHours() {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/company/workhours`, { headers: header });
  }
  getTitles() {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/company/titles`, { headers: header });
  }
  saveTitle(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/company/title/save`, request, { headers: header });
  }
  deleteTitle(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/company/title/delete`, request, { headers: header });
  }
  getTitle(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/company/title`, request, { headers: header });
  }
  saveWorkHours(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/company/workhours/save`, request, {
      headers: header,
    });
  }
  saveOrganization(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/company/organization/save`, request, {
      headers: header,
    });
  }
  saveReport(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/company/report/save`, request, { headers: header });
  }
  deleteReport(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/company/report/delete`, request, {
      headers: header,
    });
  }
  getReport(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/company/report`, request, { headers: header });
  }
  getReports() {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/company/reports`, { headers: header });
  }
  getOrganization() {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/company/organization`, { headers: header });
  }
}
export default new CompanyService();
