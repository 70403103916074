import axios from 'axios';
import AuthHeader from './auth-header';
const { authHeader } = AuthHeader();
const API_URL = process.env.VUE_APP_API_URL;

class SettingService {
  deleteCompany(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/setting/company/delete`, request, {
      headers: header,
    });
  }
  getCompanies() {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/setting/companies`, { headers: header });
  }
  saveCompany(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/setting/company/save`, request, { headers: header });
  }
  getCompany(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/setting/company`, request, { headers: header });
  }
  getPermissions() {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/setting/permissions`, { headers: header });
  }
  savePermission(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/setting/permission/save`, request, {
      headers: header,
    });
  }
  getLicense(request) {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/setting/license`, { headers: header });
  }
  downloadApp(request) {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/setting/download`, { headers: header });
  }
  downloadDetails(request) {
    return axios.post(`${API_URL}/webapi/wizard/download`, request);
  }
  getInvitations() {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/setting/invitations`, { headers: header });
  }
  saveLicense(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/setting/license/save`, request, { headers: header });
  }
  getAdvances() {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/setting/advances`, { headers: header });
  }
  saveAdvance(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/setting/advance/save`, [request], {
      headers: header,
    });
  }
}
export default new SettingService();
