import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vSelect from 'vue-select';
import CodeSnippet from 'one-code-snippet';
// import {ColorPicker, ColorPanel} from '../dist/build.js'
// import { ColorPicker, ColorPanel } from '@/components/color'; // bu

import '@/assets/styles/reset.css';

import '@/assets/fonts/poppins.css';
import VueClickAway from 'vue3-click-away';
import DataTable from '@/components/newDataTable/DataTable.vue';
import { languageServiceProvider } from './components/newDataTable/lang.js';
export { DataTable, DataTable as default, languageServiceProvider };

import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css';
window.hljs = hljs;

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

// TOOLTIP DROPDOWN
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';

import DisableAutocomplete from 'vue-disable-autocomplete';

import '@/assets/icons/icons.css';
import '@/assets/icons/iconPaths.css';
import FLIcon from '@/assets/icons/flIcon.vue';

import 'gitart-vue-dialog/dist/style.css';
import { GDialog } from 'gitart-vue-dialog';

import 'v-calendar/dist/style.css';
import VCalendar from 'v-calendar';
import { SetupCalendar } from 'v-calendar';
// import moment from 'moment';

// intercom chat
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-3';

// notification
import Notifications from '@kyvg/vue3-notification';
import '@/components/notifications/notification_styles.scss';

// onboarding
import '@/components/onboarding/onboardingStyle.css';

import VueShepherdPlugin from 'vue-shepherd';
import 'shepherd.js/dist/css/shepherd.css';
// v3-tour => onboarding
// import VueTour from "v3-tour";
// import "v3-tour/dist/vue-tour.css";
// import "v3-tour/dist/vue-tour.css";

// recaptcha
import '@/assets/styles/recaptcha.scss';
// grid css
import '@/assets/styles/_grid.scss';
import i18n from './i18n';

// UI
import FLButton from '@/components/UI/FLButton.vue';

// tooltip ui theme
import '@/assets/styles/_tooltip.scss';

const app = createApp(App);
app.use(FloatingVue, {
  defaultTrigger: 'hover focus click',
  themes: {
    dark: {
      triggers: ['hover'],
      autoHide: true,
      placement: 'top'
    },
    light: {
      triggers: ['hover'],
      autoHide: true,
      placement: 'top'
    }
  }
});
app.use(store);
app.use(VueShepherdPlugin);
// app.use(createPinia);
app.use(router);
//app.use(DisableAutocomplete);
app.use(FloatingVue);
app.use(i18n);
// app.use(moment);
app.use(VCalendar, {});
app.use(SetupCalendar, {});
app.use(VueClickAway);
app.use(TawkMessengerVue, {
  propertyId: '630df40a37898912e96629a3',
  widgetId: '1gctq578p',
});
app.use(Notifications);
// app.use(VueTour);
// window.tours = app.config.globalProperties.$tours;

// UI
app.component('fl-button', FLButton);

app.component('v-select', vSelect);
app.component('Datepicker', Datepicker);
app.component('GDialog', GDialog);
app.component('fl-icon', FLIcon);
app.mount('#app');
