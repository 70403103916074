<template>
  <div class="data-table-sorting-icons">
    <div class="icon asc"></div>
    <div class="icon desc"></div>
  </div>
</template>
<script>
export default {
  name: 'DataTableSortingIcon',
};
</script>
<style lang="scss">
.data-table-sorting-icons {
  display: flex;
  margin-left: auto;
  padding-left: 0.3em;
  .icon {
    width: 0.75em;
    display: block;
    height: 1em;
    &.asc::after {
      content: '\2193';
    }
    &.desc::after {
      content: '\2191';
    }
    &.asc::after,
    &.desc::after {
      font-size: 120%;
      line-height: 1;
      display: block;
      color: black;
      opacity: 0.5;
    }
    [data-sorting='asc'] &.asc::after {
      opacity: 1;
    }
    [data-sorting='desc'] &.desc::after {
      opacity: 1;
    }
  }
}
</style>
