import axios from 'axios';
import AuthHeader from './auth-header';
const { authHeader } = AuthHeader();
const API_URL = process.env.VUE_APP_API_URL;

class ReportService {
  queryReport(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/report/query`, request, { headers: header });
  }
  getFilters() {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/report/filters`, { headers: header });
  }
}
export default new ReportService();
