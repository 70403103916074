import axios from 'axios';
import AuthHeader from './auth-header';
const { authHeader } = AuthHeader();
const API_URL = process.env.VUE_APP_API_URL;

class DownloadService {
  getDownload(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/download`, request, { headers: header });
  }
}
export default new DownloadService();
