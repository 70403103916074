import PaymentService from '@/services/payment.service';
import { reactive } from 'vue';
const state = reactive({
  Billing: {
    Invoices: [],
  },
  Subscription: {
    TariffName: '',
    Country: 'TR',
    Province: 34,
    Expire: '',
    Amount: '',
    Quantity: 0,
    DiscountAmount: null,
    CouponAmount: null,
    CouponCode: null,
  },
});
const getters = {
  getPaymentBilling() {
    return state.Billing;
  },
  getPaymentSubscription() {
    return state.Subscription;
  },
};
const mutations = {
  getCoupon(state, payload) {
    state.Subscription.CouponAmount = payload.DTO.Amount > 0 ? `${payload.DTO.Amount}` : null;
  },
  unsubscribe(state, payload) {
    state.Subscription.HasCardSaved = false;
    state.Subscription.CardHolderName = '';
    state.Subscription.CardAlias = '';
  },
  saveSubscription(state, payload) {},
  setSubscription(state, payload) {
    Object.keys(payload).forEach((key) => {
      state.Subscription[key] = payload[key];
    });
  },
  completePayment(state, payload) {},
  getBilling(state, payload) {
    state.Billing = payload;
  },
  getSubscription(state, payload) {
    state.Subscription = payload;
  },
};
const actions = {
  getCoupon(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      PaymentService.getCoupon(request)
        .then((res) => {
          context.commit('getCoupon', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  unsubscribe(context, payload) {
    let request = payload;
    PaymentService.unsubscribe()
      .then((res) => {
        context.commit('unsubscribe', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  saveSubscription(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      PaymentService.saveSubscription(request)
        .then((res) => {
          context.commit('saveSubscription', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  setSubscription(context, payload) {
    context.commit('setSubscription', payload);
  },
  getPaymentSuccess(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      PaymentService.getPaymentSuccess(request)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  getBilling(context, payload) {
    PaymentService.getBilling()
      .then((res) => {
        context.commit('getBilling', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  getSubscription(context) {
    PaymentService.getSubscription()
      .then((res) => {
        context.commit('getSubscription', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
