<template>
  <div class="data-table-search-filter">
    <!-- <CustomInput isTableInput @input="catchInputData" style="margin-right: 10px" /> -->
  </div>
</template>
<script>
import CustomInput from '@/components/input/CustomInput.vue';
export default {
  name: 'DataTableSearchFilter',
  props: {
    searchText: String,
    search: String,
  },
  components: { CustomInput },
  methods: {
    catchInputData(e) {
      this.$emit('set-search', e);
    },
  },
};
</script>

<style lang="scss" scoped>
.data-table-search-filter {
  grid-area: search;
  display: flex;
  align-items: center;
  input {
    margin: 0;
    color: #495057;
    outline: none;
    background-color: #fff;
    border: 1px solid #ced4da;
    padding: 0.25rem 0.5rem;
    line-height: 1.5;
    border-radius: 6px;
    margin-left: 0.5em;
    display: inline-block;
  }
}
</style>
