import axios from 'axios';
import AuthHeader from './auth-header';
const { authHeader } = AuthHeader();
const API_URL = process.env.VUE_APP_API_URL;

class CategoryService {
  getAllocations() {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/category/allocations`, { headers: header });
  }
  queryAllocations(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/category/allocations/query`, request, {
      headers: header,
    });
  }
  getAllocation(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/category/allocation`, request, { headers: header });
  }
  saveAllocation(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/category/allocation/save`, request, {
      headers: header,
    });
  }
  deleteWebAddress(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/category/webaddress/delete`, request, {
      headers: header,
    });
  }
  getWebAddresses() {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/category/webaddresses`, { headers: header });
  }
  queryWebAddresses(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/category/webaddresses/query`, request, {
      headers: header,
    });
  }
  getWebAddress(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/category/webaddress`, request, { headers: header });
  }
  saveWebAddress(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/category/webaddress/save`, request, {
      headers: header,
    });
  }
}

export default new CategoryService();
