import PromotionService from '@/services/promotion.service';
import { reactive } from 'vue';
const state = reactive({
  Promotions: {
    Earned: [],
    Promoted: [],
  },
  Promotion: null,
  Promote: {
    PromotionLink: '',
  },
});
const getters = {
  getAllPromotions(state) {
    return state.Promotions;
  },
  getPromote(state) {
    return state.Promote;
  },
};
const mutations = {
  savePromotion(state, payload) {
    let emails = payload.DTO.map((p) => p.EmailAddress);
    state.Promotions.Promoted = state.Promotions.Promoted.filter(
      (p) => !emails.includes(p.EmailAddress),
    );
    state.Promotions.Promoted = [...state.Promotions.Promoted, ...payload.DTO];
  },
  promote(state, payload) {
    state.Promote = payload;
  },
  getPromotions(state, payload) {
    state.Promotions = payload;
  },
};
const actions = {
  getPromotions(context, payload) {
    let request = payload;
    PromotionService.getPromotions(request)
      .then((res) => {
        context.commit('getPromotions', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  savePromotion(context, payload) {
    let request = payload;
    PromotionService.savePromotion(request)
      .then((res) => {
        context.commit('savePromotion', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  promote(context, payload) {
    let request = payload;
    PromotionService.promote(request)
      .then((res) => {
        context.commit('promote', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
