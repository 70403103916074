import DownloadService from '@/services/download.service';
import { reactive } from 'vue';
const state = reactive({
  Token: null,
});
const getters = {
  getDownloadToken() {
    return state.Token;
  },
};
const mutations = {
  getDownload(state, payload) {
    state.Token = {
      ExpireDate: payload.DTO.ExpireDate,
      DownloadKey: payload.DTO.DownloadKey,
    };
  },
};
const actions = {
  getDownload(context, payload) {
    let request = payload;
    DownloadService.getDownload(request)
      .then((res) => {
        context.commit('getDownload', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
