import axios from 'axios';
import AuthHeader from './auth-header';
import qs from 'qs';
const { authHeader } = AuthHeader();
const API_URL = process.env.VUE_APP_API_URL;

class ProfileService {
  saveMarketing(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/profile/marketing/save`, request, {
      headers: header,
    });
  }
  saveTimezone(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/profile/timezone/save`, request, {
      headers: header,
    });
  }
  saveLanguageCode(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/profile/language/save`, request, {
      headers: header,
    });
  }
  getProfile() {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/profile`, { headers: header });
  }
  savePassword(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/profile/password/save`, request, {
      headers: header,
    });
  }
  saveProfileImage(request) {
    const header = authHeader();
    header['Content-Type'] = 'multipart/form-data';
    return axios.post(`${API_URL}/upload/profileimage`, request, {
      headers: header,
    });
  }
  saveEmployeeImage(request) {
    const header = authHeader();
    header['Content-Type'] = 'multipart/form-data';
    return axios.post(`${API_URL}/upload/employeeimage`, request, {
      headers: header,
    });
  }

  saveProfile(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/profile/save`, request, { headers: header });
  }
  confirmProfile() {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/profile/confirm`, { headers: header });
  }
}
export default new ProfileService();
