import axios from 'axios';
import AuthHeader from './auth-header';
const { authHeader } = AuthHeader();
const API_URL = process.env.VUE_APP_API_URL;

class PromotionService {
  getPromotions() {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/promotions`, { headers: header });
  }
  promote() {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/promotion/promote`, { headers: header });
  }
  savePromotion(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/promotion/save`, request, {
      headers: header,
    });
  }
}
export default new PromotionService();
