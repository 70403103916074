import store from "..";

const state = {
  isDateSelected: false,
};

const getters = {
  getIsDateSelected(state) {
    return state.isDateSelected;
  },
};

const mutations = { 
  setMutateHandler(state, input) {
    const {data, type } = input
    state[type] = data;
  },
};

const actions = {
  setIsDateSelected({ commit }, data) {
    let input = {
      data: data,
      type: "isDateSelected",
    };
    console.log('here ', input);
    commit("setMutateHandler", input);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
