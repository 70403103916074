import ProfileService from '@/services/profile.service';
const API_URL = process.env.VUE_APP_API_URL;
import axios from 'axios';
// import authHeader from './auth-header';
import authHeader from '@/services/auth-header';
import { reactive } from 'vue';
const state = reactive({
  Employee: {
    Fullname: null,
    Title: null,
    ImageUrl: null,
    EmailAddress: null,
    UserTitle: null,
    IsMailSubscribe: false,
    IsUnsubscribe: false,
    EmailConfirmed: false,
    TimeZone: null,
    LanguageCode: null,
    Timezones: [],
    Languages: [],
  },
});
const getters = {
  getProfileEmployee(state) {
    return state.Employee;
  },
  getProfileTimezones(state) {
    return state.Timezones;
  },
  getProfileLanguages(state) {
    return state.Languages;
  },
};
const mutations = {
  saveMarketing(state, payload) {
    state.Employee.IsMailSubscribe = payload.IsMailSubscribe;
  },
  saveTimezone(state, payload) {
    state.Employee.TimeZone = payload.TimeZone;
  },
  saveLanguageCode(state, payload) {
    state.Employee.LanguageCode = payload.LanguageCode;
  },
  getProfile(state, payload) {
    state.Employee.Fullname = payload.Employee.fullname;
    state.Employee.Title = payload.Employee.title;
    state.Employee.ImageUrl = payload.ImagePath;
    state.Employee.EmailAddress = payload.EmailAddress;
    state.Employee.UserTitle = payload.UserTitle;
    state.Employee.IsMailSubscribe = payload.IsMailSubscribe;
    state.Employee.IsUnsubscribe = payload.IsUnsubscribe;
    state.Employee.EmailConfirmed = payload.EmailConfirmed;
    state.Employee.TimeZone = payload.TimeZone;
    state.Employee.LanguageCode = payload.LanguageCode;
    state.Languages = payload.Languages.map((p) => ({ id: p.Code, text: p.Name }));
    state.Timezones = payload.Timezones.map((p) => ({ id: p.ID, text: p.Name }));
    localStorage.setItem('user', JSON.stringify(payload.Wizard));
  },
  savePassword(state, payload) {},
  confirmProfile(state, payload) {},
  saveProfile(state, payload) {
    state.Employee.Fullname = payload.Fullname;
    if (state.Employee.EmailAddress != payload.EmailAddress) state.Employee.EmailConfirmed = false;
    state.Employee.EmailAddress = payload.EmailAddress;
  },
};
const actions = {
  saveMarketing(context, payload) {
    let request = payload;
    ProfileService.saveMarketing(request)
      .then((res) => {
        context.commit('saveMarketing', request);
      })
      .catch((err) => console.log('catch ', err));
  },
  saveTimezone(context, payload) {
    let request = payload;
    ProfileService.saveTimezone(request)
      .then((res) => {
        context.commit('saveTimezone', request);
      })
      .catch((err) => console.log('catch ', err));
  },
  saveLanguageCode(context, payload) {
    let request = payload;
    ProfileService.saveLanguageCode(request)
      .then((res) => {
        context.commit('saveLanguageCode', request);
      })
      .catch((err) => console.log('catch ', err));
  },
  getProfile(context, payload) {
    return new Promise(function (resolve, reject) {
      ProfileService.getProfile()
        .then((res) => {
          context.commit('getProfile', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  savePassword(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      ProfileService.savePassword(request)
        .then((res) => {
          context.commit('savePassword', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  confirmProfile(context, payload) {
    ProfileService.confirmProfile()
      .then((res) => {
        context.commit('confirmProfile', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },

  saveProfileImage(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      ProfileService.saveProfileImage(request)
        .then((res) => {
          context.commit('saveProfileImage', request);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },

  saveProfile(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      ProfileService.saveProfile(request)
        .then((res) => {
          context.commit('saveProfile', request);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
