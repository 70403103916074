import axios from 'axios';
import AuthHeader from './auth-header';
const { authHeader } = AuthHeader();
const API_URL = process.env.VUE_APP_API_URL;

class PaymentService {
  getCoupon(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/payment/coupon`, request, { headers: header });
  }
  unsubscribe(request) {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/payment/unsubscribe`, { headers: header });
  }
  saveSubscription(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/payment/subscribe/save`, request, {
      headers: header,
    });
  }
  getPaymentSuccess(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/payment/success`, request, { headers: header });
  }
  getBilling() {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/payment/billing`, { headers: header });
  }
  getSubscription() {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/payment/subscribe`, { headers: header });
  }
}
export default new PaymentService();
