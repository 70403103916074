import axios from 'axios';
import AuthHeader from './auth-header';
import qs from 'qs';
const { authHeader } = AuthHeader();
const API_URL = process.env.VUE_APP_API_URL;

class TimesheetService {
  getTimeProjects() {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/timesheet/projects`, { headers: header });
  }
  saveTimeProject(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/timesheet/project/save`, request, {
      headers: header,
    });
  }
  deleteTimeProject(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/timesheet/project/delete`, request, {
      headers: header,
    });
  }
  getTimeTags() {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/timesheet/tags`, { headers: header });
  }
  saveTimeTag(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/timesheet/tag/save`, request, {
      headers: header,
    });
  }
  deleteTimeTag(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/timesheet/tag/delete`, request, {
      headers: header,
    });
  }
  getTimeTasks() {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/timesheet/tasks`, { headers: header });
  }
  getTimeMembers() {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/timesheet/members`, { headers: header });
  }
  saveTimeTask(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/timesheet/task/save`, request, {
      headers: header,
    });
  }
  deleteTimeTask(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/timesheet/task/delete`, request, {
      headers: header,
    });
  }
  getTimeEntries(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/timesheet/entries`, request, {
      headers: header,
    });
  }
  getTimeWeekEntries(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/timesheet/week`, request, {
      headers: header,
    });
  }

  getTimeClocks(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/timesheet/clocks`, request, {
      headers: header,
    });
  }
  saveTimeEntry(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/timesheet/entry/save`, request, {
      headers: header,
    });
  }
  saveTimeEntryDetail(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/timesheet/detail/save`, request, {
      headers: header,
    });
  }
  updateTimeEntry(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/timesheet/entry/update`, request, {
      headers: header,
    });
  }
  deleteTimeEntryRange(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/timesheet/entryrange/delete`, request, {
      headers: header,
    });
  }
  deleteTimeEntry(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/timesheet/entry/delete`, request, {
      headers: header,
    });
  }
}
export default new TimesheetService();
