import CategoryService from '@/services/category.service';
import { reactive } from 'vue';
const state = reactive({
  WebAddresses: {
    currentPage: 1,
    numberOfEntries: 0,
    totalEntries: 0,
    data: [],
  },
  Applications: {
    currentPage: 1,
    numberOfEntries: 0,
    totalEntries: 0,
    data: [],
  },
});
const getters = {
  getCategoryWebAddresses(state) {
    return state.WebAddresses;
  },
  getCategoryApplications(state) {
    return state.Applications;
  },
};
const mutations = {
  queryAllocations(state, payload) {
    state.Applications = {
      currentPage: payload.DTO.currentPage,
      totalEntries: payload.DTO.recordsTotal,
      numberOfEntries: payload.DTO.recordsFiltered,
      data: payload.DTO.data,
    };
  },
  getAllocation(state, payload) {},
  saveAllocation(state, payload) {
    const item = state.Applications.data.find((p) => p.ID == payload.ID);
    item.Domain = payload.Domain;
    item.AlwaysOn = payload.AlwaysOn;
    item.Name = payload.Name;
  },
  deleteWebAddress(state, payload) {},
  queryWebAddresses(state, payload) {
    state.WebAddresses = {
      currentPage: payload.DTO.currentPage,
      totalEntries: payload.DTO.recordsTotal,
      numberOfEntries: payload.DTO.recordsFiltered,
      data: payload.DTO.data,
    };
  },
  getWebAddress(state, payload) {},
  saveWebAddress(state, payload) {
    const item = state.WebAddresses.data.find((p) => p.ID == payload.ID);
    item.Domain = payload.Domain;
    item.AlwaysOn = payload.AlwaysOn;
  },
};
const actions = {
  queryAllocations(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      CategoryService.queryAllocations(request)
        .then((res) => {
          context.commit('queryAllocations', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  getAllocation(context, payload) {
    let request = payload;
    CategoryService.getAllocation(request)
      .then((res) => {
        context.commit('getAllocation', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  saveAllocation(context, payload) {
    let request = payload;
    CategoryService.saveAllocation(request)
      .then((res) => {
        context.commit('saveAllocation', request);
      })
      .catch((err) => console.log('catch ', err));
  },
  deleteWebAddress(context, payload) {
    let request = payload;
    CategoryService.deleteWebAddress(request)
      .then((res) => {
        context.commit('deleteWebAddress', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  queryWebAddresses(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      CategoryService.queryWebAddresses(request)
        .then((res) => {
          context.commit('queryWebAddresses', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },

  getWebAddress(context, payload) {
    let request = payload;
    CategoryService.getWebAddress(request)
      .then((res) => {
        context.commit('getWebAddress', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  saveWebAddress(context, payload) {
    let request = payload;
    CategoryService.saveWebAddress(request)
      .then((res) => {
        context.commit('saveWebAddress', request);
      })
      .catch((err) => console.log('catch ', err));
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
