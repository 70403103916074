import SettingService from '@/services/setting.service';
import { reactive } from 'vue';
const state = reactive({
  Permissions: [],
  Advanced: {
    Left: [],
    Right: [],
  },
  License: {
    CustomerName: '',
    ExpireDate: '',
    RemainingDays: 0,
    TotalUsers: 0,
    ActiveUsers: 0,
    LicensedUsers: 0,
    RemainingUsers: 0,
  },
  Invitation: null,
  Companies: [],
  Company: null,
});
const getters = {
  getSettingPermissions(state) {
    return state.Permissions;
  },
  getSettingAdvances(state) {
    return state.Advanced;
  },
  getSettingLicense(state) {
    return state.License;
  },
  getSettingInvitation(state) {
    return state.Invitation;
  },
  getSettingDownloadWindows(state) {
    return state.DownloadWindows;
  },
  getSettingCompanies(state) {
    return state.Companies;
  },
  getSettingCompany(state) {
    return state.Company;
  },
};
const mutations = {
  deleteCompany(state, payload) {
    state.Companies = state.Companies.filter((p) => p.ID !== payload.ID);
  },
  getCompanies(state, payload) {
    state.Companies = payload;
  },
  saveCompany(state, payload) {
    state.Companies = state.Companies.filter((p) => p.ID !== payload.DTO.ID);
    state.Companies.push(payload.DTO);
  },
  getCompany(state, payload) {
    state.Company = payload.DTO;
  },
  setCompany(state, payload) {
    state.Company = payload;
  },

  getPermissions(state, payload) {
    state.Permissions = payload.map((p) => ({
      ID: p.Id,
      Key: p.Key,
      Name: p.Name,
      IsEveryone: !p.VisibleOnlyByAdmin,
      IsActive: p.Enabled,
    }));
  },
  savePermission(state, payload) {},
  getLicense(state, payload) {
    state.License = payload;
  },
  downloadWindows(state, payload) {
    state.DownloadWindows = payload.InvitationId;
  },
  getInvitations(state, payload) {},

  saveLicense(state, payload) {},
  getAdvances(state, payload) {
    state.Advanced.Left = payload
      .filter((p) => p.DataType == 2)
      .map((p) => ({
        SettingType: p.SettingType,
        TypeName: p.TypeName,
        DataType: p.DataType,
        Value: String(p.Value).toLowerCase() == 'true',
      }));
    state.Advanced.Right = payload
      .filter((p) => p.DataType == 1)
      .map((p) => ({
        SettingType: p.SettingType,
        TypeName: p.TypeName,
        DataType: p.DataType,
        Value: p.Value,
      }));
  },
  saveAdvance(state, payload) {
    let item = state.Advanced.Left.filter((p) => p.SettingType == payload.SettingType);
    if (item) item.Value = payload.Value;
    item = state.Advanced.Right.filter((p) => p.SettingType == payload.SettingType);
    if (item) item.Value = payload.Value;
  },
};
const actions = {
  deleteCompany(context, payload) {
    let request = payload;
    SettingService.deleteCompany(request)
      .then((res) => {
        context.commit('deleteCompany', request);
      })
      .catch((err) => console.log('catch ', err));
  },
  getCompanies(context, payload) {
    let request = payload;
    SettingService.getCompanies(request)
      .then((res) => {
        context.commit('getCompanies', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  saveCompany(context, payload) {
    let request = payload;
    SettingService.saveCompany(request)
      .then((res) => {
        context.commit('saveCompany', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  getCompany(context, payload) {
    let request = payload;
    SettingService.getCompany(request)
      .then((res) => {
        context.commit('getCompany', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  setCompany(context, payload) {
    context.commit('setCompany', payload);
  },
  getPermissions(context) {
    SettingService.getPermissions()
      .then((res) => {
        context.commit('getPermissions', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  savePermission(context, payload) {
    let request = payload;
    SettingService.savePermission(request)
      .then((res) => {
        context.commit('savePermission', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  getLicense(context) {
    SettingService.getLicense()
      .then((res) => {
        context.commit('getLicense', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  downloadApp(context) {
    return new Promise(function (resolve, reject) {
      SettingService.downloadApp()
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  downloadDetails(context, payload) {
    return new Promise(function (resolve, reject) {
      SettingService.downloadDetails(payload)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  getInvitations(context, payload) {
    let request = payload;
    SettingService.getInvitations(request)
      .then((res) => {
        context.commit('getInvitations', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },

  saveLicense(context, payload) {
    let request = payload;
    SettingService.saveLicense(request)
      .then((res) => {
        context.commit('saveLicense', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  getAdvances(context) {
    SettingService.getAdvances()
      .then((res) => {
        context.commit('getAdvances', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  saveAdvance(context, payload) {
    let request = payload;
    SettingService.saveAdvance(request)
      .then((res) => {
        context.commit('saveAdvance', request);
      })
      .catch((err) => console.log('catch ', err));
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
