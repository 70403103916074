<template>
  <GDialog
    v-model="getDialogValue"
    persistent
    no-click-animation
    close-on-back
    :width="width"
    :height="height"
    min-width="400"
    min-height="200"
  >
    <div class="dialog-wrapper">
      <main>
        <div class="header">
          <div class="title">
            <span>{{ title }}</span>
          </div>
          <div @click="closeModal" class="close-section">
            <fl-icon class="fl-exit fl-3x dark-blue" />
          </div>
        </div>
        <div class="content">
          <slot></slot>
        </div>
      </main>
      <footer>
        <div v-if="hasCancelButton || hasAcceptButton || hasBonusButton" class="footer">
          <fl-button
            @click="onCancel"
            v-if="hasCancelButton"
            variant="secondary"
            size="lg"
            class="mx-2"
          >
            {{ cancelButtonText }}
          </fl-button>
          <fl-button @click="submit" v-if="hasAcceptButton" variant="accept" size="lg" class="mx-2">
            {{ acceptButtonText }}
          </fl-button>
          <fl-button
            @click="submitBonus"
            v-if="hasBonusButton"
            variant="warning"
            size="lg"
            class="mx-2"
          >
            {{ bonusButtonText }}
          </fl-button>
        </div>
      </footer>
    </div>
  </GDialog>
</template>

// https://gitart-vue-dialog.gitart.org/guide/components/g-dialog.html#default //
https://www.npmjs.com/package/gitart-vue-dialog // https://examples.gitart-vue-dialog.gitart.org/
<script setup>
import { ref, defineProps, watch, computed, defineEmits, toRef } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
const { t } = useI18n({ useScope: 'global' });
const emit = defineEmits(['onCancel', 'submit', 'submitBonus']);

const store = useStore();
const getDialogValue = computed(() => store.getters[props.getterName]);

const props = defineProps({
  title: {
    type: Text,
  },
  hasAcceptButton: {
    type: Boolean,
  },
  hasCancelButton: {
    type: Boolean,
  },
  hasBonusButton: {
    type: Boolean,
  },
  acceptButtonText: {
    type: String,
    default: 'Save',
  },
  cancelButtonText: {
    type: String,
    default: 'Cancel',
  },
  bonusButtonText: {
    type: String,
    default: 'Use All',
  },
  width: {
    type: String,
    default: 'fit-content',
  },
  height: {
    type: String,
    default: 'fit-content',
  },
  getterName: {
    type: String,
    default: 'getDialogState',
  },
});
const closeModal = () => {
  // TODO: bütün modallar değiştikten sonra setDialogStateAction kaldırılacak
  store.dispatch('setDialogStateAction', false);
  store.dispatch('closeAllModals');
  emit('onCancel');
};

const onCancel = () => {
  // TODO: bütün modallar değiştikten sonra setDialogStateAction kaldırılacak
  store.dispatch('setDialogStateAction', false);
  store.dispatch('closeAllModals');
  emit('onCancel');
};

const submit = () => {
  emit('submit');
};

const submitBonus = () => {
  emit('submitBonus');
};
</script>

<style scoped lang="scss">
.dialog-wrapper {
  padding: 25px;
  min-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    border-bottom: 2px solid #f5f6f7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 13px;
    margin-bottom: 20px;
    .title {
      margin-bottom: 2px;
      span {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #000000;
      }
    }
    .close-section {
      cursor: pointer;
      background: #f3f3f5;
      border-radius: 13px;
      border: 1px solid #f3f3f5;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
    }
  }
  .footer {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .modal-btn {
      cursor: pointer;
      border-radius: 13px;
      border: 1px solid;
      padding: 10px 40px;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      margin: 0 5px;
    }
    .cancel-btn {
      background: #f3f3f5;
      border-color: #f3f3f5;
      color: #86889e;
    }
    .accept-btn {
      border-color: #4891ff;
      background-color: #4891ff;
      color: white;
    }
    .bonus-btn {
      border-color: #ffb547;
      background-color: #ffb547;
      color: white;
    }
  }
}
</style>
