export default {
  perPageText: ':entries kayıtlarını göster',
  infoText: 'Gösterilen :first ile :last arasında toplam :total kayıt',
  infoFilteredText:
    'Gösterilen :first ile :last arasında :filtered kayıt (:total kayıttan süzüldü)',
  nextButtonText: 'İleri',
  previousButtonText: 'Geri',
  paginationSearchText: 'Sayfaya git',
  paginationSearchButtonText: 'GO',
  searchText: 'search:',
  emptyTableText: 'Hiçbir eşleşen kayıt bulunamadı',
  downloadText: 'export as:',
  downloadButtonText: 'DOWNLOAD',
};
