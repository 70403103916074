import axios from 'axios';
import qs from 'qs';
import AuthHeader from './auth-header';
const { authHeader, authLoginHeader } = AuthHeader();

const API_URL = process.env.VUE_APP_API_URL;

class AuthService {
  register(request) {
    const self = this;
    return new Promise(function (resolve, reject) {
      const result = { authentication: null, user: null };
      axios
        .post(`${API_URL}/webapi/account/register`, request)
        .then((res) => {
          if (res.data.Status != 0) return reject(res.data);
          result.user = res.data.DTO;
          localStorage.setItem('user', JSON.stringify(result.user));
          const authreq = {
            username: '',
            password: '',
            code: result.user.AccessKey,
            grant_type: 'password',
          };
          return self.authenticate(authreq, result, resolve, reject);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  }
  reset(request) {
    const self = this;
    return new Promise(function (resolve, reject) {
      const result = { authentication: null, user: null };
      axios
        .post(`${API_URL}/webapi/account/reset`, request)
        .then((res) => {
          if (res.data.Status != 0) return reject(res.data);
          result.user = res.data.DTO;
          localStorage.setItem('user', JSON.stringify(result.user));
          const authreq = {
            username: '',
            password: '',
            code: result.user.AccessKey,
            grant_type: 'password',
          };
          return self.authenticate(authreq, result, resolve, reject);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  }
  authenticate(request, result, resolve, reject) {
    axios
      .post(`${API_URL}/oauth/token`, qs.stringify(request), { headers: authLoginHeader() })
      .then((res) => {
        result.authentication = res.data;
        result.authentication.issuedate = Date.now();
        localStorage.setItem('authentication', JSON.stringify(result.authentication));
        return resolve(result);
      })
      .catch((err) => {
        console.log('catch ', err);
        return reject(err);
      });
  }

  login(request) {
    const self = this;
    return new Promise(function (resolve, reject) {
      const result = { authentication: null, user: null };
      axios
        .post(`${API_URL}/oauth/token`, qs.stringify(request), { headers: authLoginHeader() })
        .then((res) => {
          result.authentication = res.data;
          result.authentication.issuedate = Date.now();
          localStorage.setItem('authentication', JSON.stringify(result.authentication));
          return self.getProfile(result, resolve, reject);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  }

  refresh() {
    return new Promise(function (resolve, reject) {
      var authentication = JSON.parse(localStorage.getItem('authentication'));
      const request = {
        grant_type: 'refresh_token',
        refresh_token: authentication.refresh_token,
      };
      axios
        .post(`${API_URL}/oauth/token`, qs.stringify(request), { headers: authLoginHeader() })
        .then((res) => {
          authentication.access_token = res.data.access_token;
          authentication.expires_in = res.data.expires_in;
          authentication.issuedate = Date.now();
          localStorage.setItem('authentication', JSON.stringify(authentication));
          return resolve(authentication);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  }
  getProfile(result, resolve, reject) {
    const languageCode = localStorage.getItem('languageCode');
    if (!languageCode) localStorage.setItem('languageCode', 'en');
    const request = { languageCode: languageCode };
    const header = authHeader();
    axios
      .post(`${API_URL}/webapi/wizard/profile`, request, { headers: header })
      .then((res) => {
        result.user = res.data;
        return resolve(result);
      })
      .catch((err) => {
        console.log('catch ', err);
        return reject(err);
      });
  }
}

export default new AuthService();
