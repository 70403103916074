import AuthService from '@/services/auth.service';
import ProfileService from '@/services/profile.service';

const authentication = JSON.parse(localStorage.getItem('authentication'));
const state = {
  authentication: null,
  user: null,
};
const mutations = {
  authorize(state, payload) {
    state.authentication = payload.authentication;
    state.user = payload.user;
    localStorage.setItem('user', JSON.stringify(payload.user));
  },
  refresh(state, payload) {
    state.authentication = payload;
  },
  logout(state) {
    state.authentication = null;
    state.user = null;
    localStorage.removeItem('authentication');
    localStorage.removeItem('user');
  },
};
const getters = {};
const actions = {
  login(context, payload) {
    return new Promise(function (resolve, reject) {
      AuthService.login(payload)
        .then((res) => {
          context.commit('authorize', res);
          return resolve(res);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  loginForm(context, payload) {
    const request = {
      username: payload.Email,
      password: payload.Password,
      recaptcha: payload.Recaptcha,
      grant_type: 'password',
    };
    //console.log('loginForm', payload);
    return context.dispatch('login', request);
  },
  loginToken(context, payload) {
    const request = {
      username: '',
      password: '',
      code: payload,
      grant_type: 'password',
    };
    //console.log('loginToken', payload);
    return context.dispatch('login', request);
  },
  register(context, payload) {
    //console.log('register', payload);
    return new Promise(function (resolve, reject) {
      AuthService.register(payload)
        .then((res) => {
          context.commit('authorize', res);
          return resolve(res);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },

  reset(context, payload) {
    //console.log('reset', payload);
    return new Promise(function (resolve, reject) {
      AuthService.reset(payload)
        .then((res) => {
          context.commit('authorize', res);
          return resolve(res);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  refresh(context, payload) {
    //console.log('reset', payload);
    return new Promise(function (resolve, reject) {
      AuthService.refresh()
        .then((res) => {
          context.commit('refresh', res);
          return resolve(res);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  logout(context) {
    context.commit('logout');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
