<template>
  <div class="data-table-table">
    <table :class="tableClass">
      <thead>
        <tr>
          <th
            v-for="(column, i) in columns"
            :key="i"
            :data-sorting="column.sortingMode"
            :class="{ sortable: column.sortable }"
            class="column"
            @click="$emit('sort-column', column)"
          >
            <div class="column-content">
              <div>
                <span>{{ column.title }}</span>
                <component
                  :index="column.sortingIndex"
                  :is="sortingIndexComponent"
                  v-if="column.sortingIndex > 0"
                />
                <component :is="sortingIconComponent" v-if="column.sortable" />
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody v-if="!isLoading">
        <tr v-if="isEmpty">
          <td :colspan="numberOfColumns" class="empty-table-cell">
            {{ emptyTableText }}
          </td>
        </tr>
        <!-- Invitation -->
        <tr
          v-for="(invitation, i) in invitations"
          :key="i"
          @click="rowClick(invitation)"
          class="row-tr"
        >
          <InvitationSent :columnCount="numberOfColumns" :data="invitation" />
        </tr>
        <tr v-for="(data, i) in dataDisplayed" :key="i" @click="rowClick(data)" class="row-tr">
          <td
            v-for="(column, j) in columns"
            :key="j"
            :style="column.style"
            style="vertical-align: middle"
          >
            <component
              v-if="column.component"
              :is="column.component"
              :data="data"
              :column="column"
            />
            <!-- <span v-else contenteditable>{{ data[column.key] }}</span> -->
            <span v-else class="table-text" :title="data[column.key]">{{ data[column.key] }}</span>
          </td>
        </tr>
      </tbody>
      <component v-if="isLoading" :is="loadingComponent" />
      <component
        v-if="footerComponent !== null"
        :is="footerComponent"
        v-bind="{ data, dataDisplayed, dataFiltered }"
      />
    </table>
  </div>
</template>
<script>
import InvitationSent from '@/components/dataTable/InvitationSent.vue';
export default {
  name: 'DataTableTable',
  props: {
    tableClass: String,
    columns: Array,
    data: Array,
    dataDisplayed: Array,
    invitations: Array,
    dataFiltered: Array,
    emptyTableText: String,
    footerComponent: [Object, String],
    isEmpty: Boolean,
    isLoading: Boolean,
    loadingComponent: [Object, String],
    numberOfColumns: Number,
    sortingIconComponent: Object,
    sortingIndexComponent: Object,
  },
  components: {
    InvitationSent,
  },
  methods: {
    rowClick(data) {
      // console.log('this ', data);
    },
  },
};
</script>
<style lang="scss" scoped>
.data-table-table {
  overflow: auto;
  // max-height: 80vh;
  width: 100%;
  grid-area: table;
  table {
    margin: 10px 0 30px 0;
    border: none;
    font-family: 'Poppins';
    border-collapse: collapse;
    width: 100%;
    background: #ffffff;
    table-layout: auto;
    box-sizing: border-box;
  }
  .column,
  .action-column {
    white-space: nowrap;
    background-color: white;
    position: sticky;
    padding: 1rem 0.75rem;
    border: none !important;
    line-height: 1;
    top: 0;
    user-select: none;
    &.sortable {
      cursor: pointer;
      &:hover {
        color: #3490dc;
      }
    }
  }
  .action-column {
    text-align: start;
  }
  .column-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .empty-table-cell {
    text-align: center;
    padding: 50px 0 !important;
  }
  .action-cell {
    text-align: start;
    white-space: nowrap;
    vertical-align: baseline;
  }
}

th {
  text-align: start !important;
  padding: 18px !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #0e123e;
}
td {
  text-align: start;
  padding: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #0e123e;
  vertical-align: middle !important;
}
.row-tr {
  width: 90%;
}
.row-tr:nth-child(odd) {
  background-color: #f3f3f5;
}
tr td:first-child {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
tr td:last-child {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

@media screen and (min-width: 1441px) {
  td {
    padding: 18px;
    max-width: 310px;
  }
}

/* Styles for larger screens */
@media screen and (max-width: 1440px) {
  td {
    padding: 10px;
    max-width: 171px;
  }
  .column-content {
    white-space: normal;
    word-wrap: break-word;
  }
}
</style>
