<template>
  <div class="data-table" :style="tableButtonPropsComputed.style">
    <fl-button
      v-if="tableButtonPropsComputed.isVisible"
      @click="onButtonClicked"
      :icon="`${tableButtonPropsComputed.icon} fl-4x`"
      variant="outlined-primary"
      size="lg"
    >
      {{ tableButtonPropsComputed.text }}
    </fl-button>
    <DataTableSearchFilter
      v-if="showSearchFilter"
      v-bind="propsSearchFilter"
      @set-search="setSearch"
    />
    <DataTableTable v-bind="propsTable" @sort-column="sortColumn" />
    <DataTableEntriesInfo
      v-if="showEntriesInfo"
      v-bind="propsEntriesInfo"
      style="margin-right: 20px"
    />
    <DataTablePagination v-if="showPagination" v-bind="propsPagination" @set-page="setPage" />
    <!-- <DataTableExportData
			v-if="showDownloadButton"
			v-bind="propsExportData"
		/> -->
    <DataTablePerPage
      v-if="showPerPage"
      v-bind="propsPerPage"
      @set-per-page="setPerPageFromUserInput"
    />
  </div>
</template>
<script>
import DataTableEntriesInfo from './EntriesInfo/EntriesInfo.vue';
import DataTablePerPage from './PerPage/PerPage.vue';
import DataTableExportData from './ExportData/ExportData.vue';
import DataTablePagination from './Pagination/Pagination.vue';
import DataTableSearchFilter from './SearchFilter/SearchFilter.vue';
import DataTableTable from './Table/Table.vue';
import DataTableSortingIcon from './SortableColumn/SortingIcon.vue';
import DataTableSortingIndex from './SortableColumn/SortingIndex.vue';
import {
  range,
  isNullable,
  sortDataByColumn,
  stringReplaceFromArray,
  getEventTargetValue,
} from './helpers.js';
import { parseColumnProps, parseTextProps } from './parser';
import { mapGetters } from 'vuex';
export default {
  name: 'DataTable',
  components: {
    DataTableEntriesInfo,
    DataTableSearchFilter,
    DataTablePagination,
    DataTablePerPage,
    DataTableTable,
    DataTableExportData,
  },
  computed: {
    tableButtonPropsComputed() {
      let cloneDefaultProps = { ...this.tableButtonPropsDefaultValue };
      for (const prop in this.tableButtonProps) {
        cloneDefaultProps[prop] = this.tableButtonProps[prop];
      }
      return cloneDefaultProps;
    },
    tableButtonPropsDefaultValue() {
      return {
        icon: 'fl-addpeople',
        text: 'Add Team Member',
        isVisible: false,
        class: '',
      };
    },
    getTableInput() {
      return this.$store.getters.getTableInput;
    },
    numberOfColumns() {
      return this.parsedColumns.length;
    },
    searchableColumns() {
      return this.parsedColumns.filter((column) => column.searchable);
    },
    sortableColumns() {
      return this.parsedColumns.filter((column) => column.sortable);
    },
    dataDisplayed() {
      const { lastEntry, firstEntry, dataSorted, isAsync, asyncData } = this;
      if (isAsync) return asyncData.data;
      const end = lastEntry;
      const start = Math.max(0, firstEntry - 1);
      return dataSorted.slice(start, end);
    },
    dataFiltered() {
      const { searchableColumns, data, getTableInput, isAsync, asyncData } = this;
      if (isAsync) {
        this.checkSearch(getTableInput);
        this.setPageAsync(asyncData.currentPage);
        return asyncData.data;
      }
      const search = getTableInput;
      if (isNullable(search)) {
        return data;
      }
      return data.filter(function (row) {
        return searchableColumns.some(function (column) {
          const cell = column.key,
            value = row[cell];
          if (typeof value === 'string') {
            return value.toLowerCase().includes(search.toLowerCase());
          }
          if (typeof value === 'number') {
            return value.toString().includes(search);
          }
          return false;
        });
      });
    },
    dataSorted() {
      var { dataFiltered: data, columnsBeingSorted, isAsync, asyncData } = this;
      if (isAsync) return asyncData.data;
      if (columnsBeingSorted.length === 0 || data.length === 0) {
        return data;
      }
      data = [...data];
      var columns = [...columnsBeingSorted];

      columns.reverse();
      columns.forEach((column) => sortDataByColumn(data, column));
      return data;
    },
    isEmpty() {
      return (
        this.dataDisplayed.length === 0 && (!this.invitations || this.invitations.length === 0)
      );
    },
    firstEntry() {
      const { dataFiltered, currentPerPage, currentPage } = this;
      if (dataFiltered.length === 0) {
        return 0;
      }
      return currentPerPage * (currentPage - 1) + 1;
    },
    lastEntry() {
      return Math.min(this.filteredEntries, this.firstEntry + this.currentPerPage - 1);
    },
    totalEntries() {
      if (this.isAsync) return this.asyncData.totalEntries;
      return this.data.length;
    },
    filteredEntries() {
      return this.dataFiltered.length;
    },
    entriesInfoText() {
      const { infoText, infoFilteredText, firstEntry, lastEntry, filteredEntries, totalEntries } =
        this;
      const replacements = [firstEntry, lastEntry, filteredEntries, totalEntries];
      const searchValues = [':first', ':last', ':filtered', ':total'];
      var text = infoText;
      if (totalEntries !== filteredEntries) {
        text = infoFilteredText;
      }
      return stringReplaceFromArray(text, searchValues, replacements);
    },
    numberOfPages() {
      const numberOfEntries = this.isAsync ? this.asyncData.numberOfEntries : this.filteredEntries;
      return Math.max(Math.ceil(numberOfEntries / this.currentPerPage), 1);
    },
    lastPage() {
      return this.numberOfPages;
    },
    isLastPage() {
      return this.currentPage === this.numberOfPages;
    },
    isFirstPage() {
      return this.currentPage === 1;
    },
    previousPage() {
      return this.currentPage - 1;
    },
    nextPage() {
      return this.currentPage + 1;
    },

    pagination() {
      const { lastPage, currentPage, nextPage, previousPage } = this;
      if (lastPage === 1) {
        return [1];
      }
      if (lastPage <= 7) {
        return range(1, lastPage);
      }
      if (lastPage > 5 && currentPage <= 2) {
        return [1, 2, 3, '...', lastPage];
      }
      if (lastPage > 8 && lastPage > currentPage + 3) {
        return [1, '...', previousPage, currentPage, nextPage, '...', lastPage];
      }
      if (lastPage > 7 && lastPage <= currentPage + 3) {
        return [1, '...', lastPage - 3, lastPage - 2, lastPage - 1, lastPage];
      } else {
        return [1];
      }
    },
    propsPerPage() {
      return {
        currentPerPage: this.currentPerPage,
        perPageSizes: this.perPageSizes,
        perPageText: this.perPageText,
      };
    },
    propsSearchFilter() {
      return {
        search: this.search,
        searchText: this.searchText,
      };
    },
    propsTable() {
      return {
        columns: this.parsedColumns,
        data: this.data,
        dataDisplayed: this.dataDisplayed,
        invitations: this.invitations,
        dataFiltered: this.dataFiltered,
        emptyTableText: this.emptyTableText,
        footerComponent: this.footerComponent,
        isEmpty: this.isEmpty,
        isLoading: this.isLoading,
        loadingComponent: this.loadingComponent,
        numberOfColumns: this.numberOfColumns,
        sortingIconComponent: this.sortingIconComponent,
        sortingIndexComponent: this.sortingIndexComponent,
        tableClass: this.tableClass,
      };
    },
    propsEntriesInfo() {
      return {
        entriesInfoText: this.entriesInfoText,
      };
    },
    propsPagination() {
      return {
        currentPage: this.currentPage,
        isFirstPage: this.isFirstPage,
        isLastPage: this.isLastPage,
        nextButtonText: this.nextButtonText,
        nextPage: this.nextPage,
        numberOfPages: this.numberOfPages,
        pagination: this.pagination,
        paginationSearchButtonText: this.paginationSearchButtonText,
        paginationSearchText: this.paginationSearchText,
        previousButtonText: this.previousButtonText,
        previousPage: this.previousPage,
      };
    },
    propsExportData() {
      return {
        allowedExports: this.allowedExports,
        data: this.dataDisplayed,
        downloadButtonText: this.downloadButtonText,
        downloadFileName: this.downloadFileName,
        downloadText: this.downloadText,
      };
    },
  },

  mounted() {
    this.enableAsync = false;
    this.setDefaults();
    this.enableAsync = true;
  },

  data() {
    return {
      enableAsync: false,
      previousSearch: '',
      currentPage: 1,
      currentPerPage: 5,
      parsedColumns: [],
      columnsBeingSorted: [],
      perPageText: '',
      downloadText: '',
      downloadButtonText: '',
      emptyTableText: '',
      infoText: '',
      infoFilteredText: '',
      nextButtonText: '',
      previousButtonText: '',
      paginationSearchText: '',
      paginationSearchButtonText: '',
      search: '',
      searchText: '',
    };
  },

  methods: {
    isValidPage(page) {
      return (
        typeof page === 'number' &&
        page <= this.numberOfPages &&
        page > 0 &&
        page !== this.currentPage
      );
    },
    onButtonClicked() {
      this.$emit('buttonClick');
    },
    parseColumnProps() {
      var parsedColumns = parseColumnProps(this.$props);
      Object.assign(this, { parsedColumns });
    },
    parseTextProps() {
      Object.assign(this, parseTextProps(this.$props));
    },
    sortColumn(column) {
      if (!column.sortable) {
        return;
      }
      if (this.sortingMode === 'single') {
        for (let col of this.sortableColumns) {
          if (col.id !== column.id) {
            col.sortingMode = '';
            col.sortingIndex = -1;
          }
        }
        if (column.sortingMode === '') {
          column.sortingMode = 'asc';
          this.columnsBeingSorted = [column];
          this.updateAsync();

          return;
        }
        if (column.sortingMode === 'asc') {
          column.sortingMode = 'desc';
          this.columnsBeingSorted = [column];
          this.updateAsync();

          return;
        }
        column.sortingMode = '';
        this.columnsBeingSorted = [];
        this.updateAsync();
        return;
      }
      if (column.sortingMode === '') {
        column.sortingMode = 'asc';
        column.sortingIndex = this.columnsBeingSorted.length + 1;
        this.columnsBeingSorted.push(column);
        return;
      }
      if (column.sortingMode === 'asc') {
        column.sortingMode = 'desc';
        this.columnsBeingSorted.splice(column.sortingIndex - 1, 1, column);
        return;
      }
      column.sortingMode = '';
      column.sortingIndex = -1;
      this.columnsBeingSorted = this.columnsBeingSorted.filter(function (c) {
        return c.id !== column.id;
      });
      this.columnsBeingSorted.forEach(function (col, i) {
        col.sortingIndex = i + 1;
      });
    },
    setDefaults() {
      this.setPerPage(this.defaultPerPage);
    },
    setPage(value) {
      if (this.isValidPage(value)) {
        this.currentPage = value;
      }
      this.updateAsync();
    },
    setPageAsync(value) {
      if (this.isValidPage(value)) {
        this.currentPage = value;
      }
    },
    checkSearch(getTableInput) {
      if (this.previousSearch != getTableInput) this.updateAsync();
      this.previousSearch = getTableInput;
    },
    updateAsync() {
      if (!this.enableAsync) return;
      let sort = '';
      let descending = false;
      if (this.columnsBeingSorted.length > 0) {
        const column = this.columnsBeingSorted[0];
        descending = column.sortingMode === 'desc';
        sort = column.key;
      }
      const params = {
        start: this.currentPage,
        length: this.currentPerPage,
        search: this.getTableInput,
        sort: sort,
        descending: descending,
      };
      this.$emit('async-update', params);
    },
    setPerPage(value) {
      var previousFirstEntry, newPerPage, newCurrentPage;
      previousFirstEntry = this.firstEntry;
      newPerPage = this.currentPerPage;

      if (!this.perPageSizes.includes(newPerPage)) {
        newPerPage = this.perPageSizes[0];
      }
      if (this.perPageSizes.includes(value)) {
        newPerPage = value;
      }
      this.currentPerPage = newPerPage;

      newCurrentPage = Math.floor(previousFirstEntry / newPerPage) + 1;
      this.setPage(newCurrentPage);
    },
    setPerPageFromUserInput() {
      const value = Number(getEventTargetValue());
      this.setPerPage(value);
    },
    setSearch() {
      // const value = getEventTargetValue() || "";
      // this.search = value.trim();
      this.search = this.getTableInput;
      this.currentPage = 1;
    },
  },

  props: {
    tableButtonProps: {
      type: Object,
    },
    allowedExports: {
      type: Array,
      default: () => ['xls', 'csv', 'json', 'txt'],
    },
    columns: {
      type: Array,
      required: false,
    },
    columnKeys: {
      type: Array,
      required: false,
    },
    data: {
      type: Array,
      required: true,
    },
    invitations: {
      type: Array,
      required: false,
    },
    isAsync: {
      type: Boolean,
      required: false,
    },
    asyncData: {
      type: Object,
      required: false,
    },
    defaultColumn: {
      type: Object,
      required: false,
      default: function () {
        return {
          sortable: true,
          searchable: true,
          type: 'string',
        };
      },
    },
    defaultPerPage: {
      type: Number,
      default: 5,
    },
    downloadFileName: {
      type: String,
      default: 'download',
    },
    footerComponent: {
      type: [Object, String],
      default: null,
    },
    perPageSizes: {
      type: Array,
      default: () => [10, 25, 50, 100],
    },
    lang: {
      type: String,
      default: 'en',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    loadingComponent: {
      type: [Object, String],
      default: () => '',
    },
    showEntriesInfo: {
      type: Boolean,
      default: true,
    },
    showPerPage: {
      type: Boolean,
      default: true,
    },
    showDownloadButton: {
      type: Boolean,
      default: true,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    showSearchFilter: {
      type: Boolean,
      default: true,
    },
    sortingMode: {
      type: String,
      default: 'multiple',
      validator: (value) => ['multiple', 'single', 'disabled'].includes(value),
    },
    sortingIndexComponent: {
      type: Object,
      default: function () {
        return DataTableSortingIndex;
      },
    },
    sortingIconComponent: {
      type: Object,
      default: function () {
        return DataTableSortingIcon;
      },
    },
    tableClass: {
      type: String,
      default: 'table table-striped table-hover',
    },
    text: {
      type: Object,
      required: false,
    },
  },
  watch: {
    columns: {
      handler: 'parseColumnProps',
      deep: true,
      immediate: true,
    },
    columnKeys: {
      handler: 'parseColumnProps',
      deep: true,
      immediate: true,
    },
    text: {
      handler: 'parseTextProps',
      deep: true,
      immediate: true,
    },
    lang: {
      handler: 'parseTextProps',
    },
    '$store.state.dataTable.tableInput': function () {
      this.setSearch();
    },
  },
};
</script>
<style lang="scss">
@import './bootstrap.scss';
.reset-box-shadow {
  box-shadow: none;
}
.data-table {
  border: 1px solid transparent;
  padding: 26px;
  background: #ffffff;
  box-shadow: 0px 0px 23px rgb(85 85 85 / 5%);
  border-radius: 20px;
  display: grid;
  width: 100%;
  & > .data-table-search-filter,
  .data-table-pagination,
  .data-table-export-data {
    margin-left: auto;
  }
  @media (min-width: 1401px) {
    grid-template-areas:
      'search search search search search'
      'table table table table table'
      'fl-button fl-button  info perPage pagination';
  }
  @media (min-width: 1051px) AND (max-width: 1400px) {
    grid-template-areas:
      'perPage search search search'
      'table table table table'
      'info pagination pagination pagination'
      '. . download download';
  }
  @media (min-width: 851px) AND (max-width: 1050px) {
    grid-template-areas:
      'perPage search search search'
      'table table table table'
      'pagination pagination pagination pagination'
      'info info download download';
  }
  @media (max-width: 800px) {
    & > .data-table-pagination {
      flex-wrap: wrap;
    }
  }
  @media (min-width: 651px) AND (max-width: 850px) {
    grid-template-areas:
      'perPage search search search'
      'table table table table'
      'pagination pagination pagination pagination'
      'info info info info'
      'download download download download';
  }
  @media (max-width: 650px) {
    grid-template-areas:
      'search search search search'
      'perPage perPage perPage perPage '
      'table table table table'
      'pagination pagination pagination pagination'
      'info info info info'
      'download download download download';

    & > .data-table-per-page {
      margin-left: auto;
    }
  }
  // & > div {
  //   // margin-top: 1rem;
  //   max-width: 100%;
  // }
}
</style>
