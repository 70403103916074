<template>
  <div>
    <span
      v-if="inputLabel"
      class="label"
      :style="[errorText ? { color: '#ff362f' } : { color: '#86889e' }]"
    >
      {{ inputLabel }}
    </span>
    <div class="input-wrapper">
      <fl-icon
        v-if="hasIcon"
        :class="[iconPath]"
        class="searchImg fl-4x dark-blue"
        @click="onSubmit('enter')"
      />

      <!-- @change="$emit('update:modelValue', $event.target.value)" -->
      <input
        :name="name"
        v-if="!isTextArea"
        :class="customClass"
        :type="typeProps"
        :placeholder="props.placeholder"
        :disabled="disabled"
        autocomplete="off"
        :value="modelValue"
        @keyup="
          onSubmit($event.target.value);
          $emit('update:modelValue', $event.target.value);
        "
        @keyup.enter="onEnter"
        :style="[
          customClass === 'input-width-customizable' ? { width: inputWidth } : { width: '100%' },
          customClass === 'input-width-customizable' && minWidth ? { minWidth: minWidth } : '',
          errorText ? { borderColor: '#ff362f' } : { borderColor: customizableBorderColor },
        ]"
        :title="errorText"
        :maxlength="maxlength"
      />
      <fl-icon
        v-if="!isTextArea && errorText"
        class="fl-warning fl-4x red"
        style="margin-left: -32px"
      />
      <fl-icon
        @click="switchPasswordIcon"
        v-if="!isTextArea && !errorText && type === 'password'"
        class="fl-4x fl-hide cursor-pointer"
        style="margin-left: -42px"
        :class="[typeProps === 'text' ? 'fl-show' : 'fl-hide']"
      />
      <textarea
        v-if="isTextArea"
        :class="customClass"
        :type="typeProps"
        :placeholder="props.placeholder"
        :disabled="disabled"
        @keyup="onSubmit"
        @keyup.enter="onEnter"
        :value="modelValue"
        @change="$emit('update:modelValue', $event.target.value)"
        :style="[
          customClass === 'input-width-customizable' ? { width: inputWidth } : { width: '100%' },
        ]"
        id="w3review"
        name="w3review"
        rows="4"
        cols="50"
        :title="errorText"
      />
    </div>
    <span v-if="errorText" class="error-text">{{ errorText }}</span>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, toRef } from 'vue';
import { useStore } from 'vuex';
import validateInput from '@/components/input/_utils/validateInput.js';

const store = useStore();
const typeProps = ref(props.type);
const props = defineProps({
  name: {
    type: String,
  },
  inputType: {
    type: String,
    default: 'text',
  },
  endIcon: {
    type: String,
  },
  customEndIconCss: {
    type: String,
  },
  modelValue: {
    type: String,
  },
  iconPath: {
    type: String,
    default: 'fl-search',
  },
  placeholder: {
    type: String,
    default: 'Search',
  },
  hasIcon: {
    type: Boolean,
    default: false,
  },
  customClass: {
    type: String,
    default: 'input-default',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  inputWidth: {
    type: String,
    default: '100%',
  },
  isTableInput: {
    type: Boolean,
    default: false,
  },
  inputLabel: {
    type: String,
  },
  type: {
    type: String,
    default: 'text',
  },
  isTextArea: {
    type: Boolean,
    default: false,
  },
  errorText: {
    type: String,
    default: '',
  },
  maxlength: {
    type: Number,
    default: null,
  },
  isCreditCardDate: {
    type: Boolean,
    default: false,
  },
  minWidth: {
    type: String,
  },
  customizableBgColor: {
    type: String,
    default: '#f3f3f5',
  },
  customizableBorderColor: {
    type: String,
    default: '#cfd0d8',
  },
  customizableOutlineColor: {
    type: String,
    default: 'none',
  },
});

const switchPasswordIcon = () => {
  if (typeProps.value === 'text') {
    typeProps.value = 'password';
  } else if (typeProps.value === 'password') {
    typeProps.value = 'text';
  }
};

const emit = defineEmits(['input', 'creditCardDateInput', 'error', 'keyup', 'enter']);
const inputText = ref('');

const onSubmit = (e) => {
  ///console.log('onSubmit ', e);
  emit('keyup', e);

  if (props.isTableInput) {
    store.dispatch('tableInputAction', e);
    if (e === 'enter') {
      inputText.value = '';
    }
  }
};
const onEnter = (e) => {
  emit('enter', e);
};
</script>

<style scoped lang="scss">
.label {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #86889e;
  margin-left: 8px;
}
.error-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #ff362f;
  margin-left: 8px;
}
.input-wrapper {
  display: flex;
  align-items: center;
  // margin-left: 7px;
  position: relative;

  .searchImg {
    left: 4px;
    top: 10px;
    position: absolute;
    z-index: 3;
    cursor: pointer;
  }
  .input-default {
    width: 220px;
    height: 52px;
    background: #f3f3f5;
    border: 1px solid #c5c6d0;
    border-radius: 13px;
    padding-left: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #86889e;
  }
  .input-sm {
    background: #f3f3f5;
    border: 1px solid #cfd0d8;
    border-radius: 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #0e123e;
    padding: 21px;
  }
  .input-block {
    background: #f3f3f5;
    border: 1px solid #cfd0d8;
    border-radius: 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #0e123e;
    padding: 16px;
    width: 100%;
  }
  .input-width-customizable {
    background: v-bind(customizableBgColor);
    border: 1px solid v-bind(customizableBorderColor);
    border-radius: 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #0e123e;
    padding: 16px 35px;
    &:focus {
      border: 1px solid #cfd0d8 !important;
    }
  }
  input {
    &:disabled {
      background: #c5c6d0 !important;
      color: white !important;
    }
    &:focus {
      border: 1px solid v-bind(customizableOutlineColor) !important;
    }
    &:active {
      border: 1px solid v-bind(customizableOutlineColor);
    }
  }
}
</style>
