const state = {
  isCountOpen: false,
};

const getters = {
  getIsCountOpen(state) {
    return state.isCountOpen;
  },
};

const mutations = { 
  setMutateHandler(state, input) {
    let data = input.data;
    let type = input.type;
    state[type] = data;
  },
};

const actions = {
  isCountOpenAction({ commit }, data) {
    let input = {
      data: data,
      type: "isCountOpen",
    };
    commit("setMutateHandler", input);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
