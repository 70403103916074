import TimesheetService from '@/services/timesheet.service';
const API_URL = process.env.VUE_APP_API_URL;
import axios from 'axios';
// import authHeader from './auth-header';
import authHeader from '@/services/auth-header';
import { reactive } from 'vue';
const state = reactive({
  Projects: [],
  Tags: [],
  Tasks: [],
  Members: [],
  TimeEntries: [],
  TimeClocks: [],
  TimeEntryClocks: {
    StartDate: null,
    EndDate: null,
    Spend: 0,
    Clocks: [],
  },
  TimeWeekEntries: [],
  TimeQuery: {
    EntryId: '',
    RecordDate: '',
    Hours: 1,
    MemberId: '',
  },
});
const getters = {
  getTimesheetProjects(state) {
    return state.Projects;
  },
  getTimesheetTags(state) {
    return state.Tags;
  },
  getTimesheetTasks(state) {
    return state.Tasks;
  },
  getTimesheetMembers(state) {
    return state.Members;
  },
  getTimesheetEntries(state) {
    return state.TimeEntries;
  },
  getTimesheetWeekEntries(state) {
    return state.TimeWeekEntries;
  },
  getTimesheetClocks(state) {
    return state.TimeClocks;
  },
  getTimeEntryClocks(state) {
    return state.TimeEntryClocks;
  },
  getTimesheetQuery(state) {
    return state.TimeQuery;
  },
};
const mutations = {
  getTimeProjects(state, payload) {
    state.Projects = payload;
  },
  saveTimeProject(state, payload) {
    state.Projects = state.Projects.filter((p) => p.ID !== payload.DTO.ID);
    state.Projects.push(payload.DTO);
  },
  deleteTimeProject(state, payload) {
    state.Projects = state.Projects.filter((p) => p.ID !== payload.ID);
  },
  getTimeTags(state, payload) {
    state.Tags = payload;
  },
  saveTimeTag(state, payload) {
    state.Tags = state.Tags.filter((p) => p.ID !== payload.DTO.ID);
    state.Tags.push(payload.DTO);
  },
  deleteTimeTag(state, payload) {
    state.Tags = state.Tags.filter((p) => p.ID !== payload.ID);
  },
  getTimeTasks(state, payload) {
    state.Tasks = payload;
  },
  getTimeMembers(state, payload) {
    state.Members = payload;
  },
  saveTimeTask(state, payload) {
    state.Tasks = state.Tasks.filter((p) => p.ID !== payload.DTO.ID);
    state.Tasks.push(payload.DTO);
  },
  deleteTimeTask(state, payload) {
    state.Tasks = state.Tasks.filter((p) => p.ID !== payload.ID);
  },
  getTimeEntries(state, payload) {
    state.TimeEntries = payload;
  },
  getTimeWeekEntries(state, payload) {
    state.TimeWeekEntries = payload;
  },
  getTimeClocks(state, payload) {
    state.TimeClocks = payload;
  },
  setTimeClocks(state, payload) {
    state.TimeClocks = payload;
  },
  setTimeEntryClocks(state, payload) {
    state.TimeEntryClocks = payload;
  },
  saveTimeEntry(state, payload) {
    state.TimeEntries = state.TimeEntries.filter((p) => p.RecordDate == payload.DTO.RecordDate);
    var group = state.TimeEntries.find((p) => p.RecordDate == payload.DTO.RecordDate);
    if (!group) {
      group = { RecordDate: payload.DTO.RecordDate, Entries: [] };
      state.TimeEntries.push(group);
    }
    group.Entries = group.Entries.filter((p) => p.ID !== payload.DTO.ID);
    group.Entries.push(payload.DTO);
  },
  setTimesheetQuery(state, payload) {
    Object.keys(payload).forEach((key) => {
      state.TimeQuery[key] = payload[key];
    });
  },
  selectTimesheetEntry(state, payload) {
    var group = state.TimeEntries.find((p) => p.RecordDate === payload.RecordDate);
    var entry = group.Entries.find((p) => p.ID === payload.ID);
    entry.Selected = payload.Selected;
  },
  deleteTimeEntryRange(state, payload) {
    var group = state.TimeEntries.find((p) => p.RecordDate === payload.RecordDate);
    var entry = group.Entries.find((p) => p.ID === payload.EntryId);
    if (entry.Ranges.length == 1)
      group.Entries = group.Entries.filter((p) => p.ID !== payload.EntryId);
    else entry.Ranges = entry.Ranges.filter((p) => p.ID !== payload.ID);
  },
  deleteTimeEntry(state, payload) {
    var group = state.TimeEntries.find((p) => p.RecordDate === payload.RecordDate);
    group.Entries = group.Entries.filter((p) => p.ID !== payload.ID);
  },
};
const actions = {
  getTimeProjects(context) {
    return new Promise(function (resolve, reject) {
      TimesheetService.getTimeProjects()
        .then((res) => {
          context.commit('getTimeProjects', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  getTimeWeekEntries(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      TimesheetService.getTimeWeekEntries(request)
        .then((res) => {
          context.commit('getTimeWeekEntries', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  saveTimeProject(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      TimesheetService.saveTimeProject(request)
        .then((res) => {
          context.commit('saveTimeProject', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  deleteTimeProject(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      TimesheetService.deleteTimeProject(request)
        .then((res) => {
          context.commit('deleteTimeProject', request);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  getTimeTags(context) {
    return new Promise(function (resolve, reject) {
      TimesheetService.getTimeTags()
        .then((res) => {
          context.commit('getTimeTags', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  saveTimeTag(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      TimesheetService.saveTimeTag(request)
        .then((res) => {
          context.commit('saveTimeTag', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  deleteTimeTag(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      TimesheetService.deleteTimeTag(request)
        .then((res) => {
          context.commit('deleteTimeTag', request);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  getTimeTasks(context) {
    return new Promise(function (resolve, reject) {
      TimesheetService.getTimeTasks()
        .then((res) => {
          context.commit('getTimeTasks', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  getTimeMembers(context) {
    return new Promise(function (resolve, reject) {
      TimesheetService.getTimeMembers()
        .then((res) => {
          context.commit('getTimeMembers', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  saveTimeTask(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      TimesheetService.saveTimeTask(request)
        .then((res) => {
          context.commit('saveTimeTask', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  deleteTimeTask(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      TimesheetService.deleteTimeTask(request)
        .then((res) => {
          context.commit('deleteTimeTask', request);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  getTimeEntries(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      TimesheetService.getTimeEntries(request)
        .then((res) => {
          context.commit('getTimeEntries', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  getTimeClocks(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      TimesheetService.getTimeClocks(request)
        .then((res) => {
          context.commit('getTimeClocks', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  setTimeEntryClocks(context, payload) {
    context.commit('setTimeEntryClocks', payload);
  },
  setTimeClocks(context, payload) {
    context.commit('setTimeClocks', payload);
  },
  saveTimeEntry(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      TimesheetService.saveTimeEntry(request)
        .then((res) => {
          context.commit('saveTimeEntry', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  saveTimeEntryDetail(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      TimesheetService.saveTimeEntryDetail(request)
        .then((res) => {
          context.commit('saveTimeEntry', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  updateTimeEntry(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      TimesheetService.updateTimeEntry(request)
        .then((res) => {
          context.commit('saveTimeEntry', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  setTimesheetQuery(context, payload) {
    context.commit('setTimesheetQuery', payload);
  },
  selectTimesheetEntry(context, payload) {
    context.commit('selectTimesheetEntry', payload);
  },
  deleteTimeEntryRange(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      TimesheetService.deleteTimeEntryRange(request)
        .then((res) => {
          context.commit('deleteTimeEntryRange', request);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  deleteTimeEntry(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      TimesheetService.deleteTimeEntry(request)
        .then((res) => {
          context.commit('deleteTimeEntry', request);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
