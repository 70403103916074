import ReportService from '@/services/report.service';
import { reactive } from 'vue';
const state = reactive({
  Filters: {
    Projects: [],
    Teams: [],
    Employees: [],
  },
  Query: {
    Projects: [],
    Teams: [],
    Employees: [],
    Billable: '',
    Interval: '1',
    Group1: '0',
    Group2: '1',
  },
  Result: {
    Summary: {
      Billable: '00:00',
      Unbillable: '00:00',
      Total: '00:00',
    },
    Graphs: {
      Main: {
        datasets: [],
      },
    },
    Grouping: [],
  },
});
const getters = {
  getElasticReportFilters() {
    return state.Filters;
  },
  getElasticReportQuery() {
    return state.Query;
  },
  getElasticReportResult() {
    return state.Result;
  },
};
const mutations = {
  getReportFilters(state, payload) {
    state.Filters = payload;
  },
  setReportQuery(state, payload) {
    state.Query = payload;
  },
  queryReport(state, payload) {
    state.Query = payload.Query;
    state.Result = payload.Result;
  },
};
const actions = {
  getReportFilters(context, payload) {
    return new Promise(function (resolve, reject) {
      ReportService.getFilters()
        .then((res) => {
          context.commit('getReportFilters', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  queryReport(context, payload) {
    let request = payload;
    return new Promise(function (resolve, reject) {
      ReportService.queryReport(request)
        .then((res) => {
          context.commit('queryReport', { Query: request, Result: res.data });
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },

  setReportQuery(context, payload) {
    context.commit('setReportQuery', payload);
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
