import CompanyService from '@/services/company.service';
import { reactive } from 'vue';
const state = reactive({
  Holidays: [],
  Reports: [],
  Holiday: null,
  Report: {
    Type: '',
    SectionId: [],
    Schedule: {
      Period: '0',
      Weeks: [],
    },
  },
  Organization: [],
  Members: [],
  ManagerTitles: [],
  Sections: [],
  ReportTypes: [],
  WorkHours: {},
  isReportHasSearch: false,
});
const getters = {
  getIsReportHasSearch(state) {
    return state.isReportHasSearch;
  },
  getCompanyReports(state) {
    return state.Reports;
  },
  getCompanyHolidays(state) {
    return state.Holidays;
  },
  getCompanyHoliday(state) {
    return state.Holiday;
  },
  getCompanyOrganization(state) {
    return state.Organization;
  },
  getCompanyMembers(state) {
    return state.Members;
  },
  getCompanyManagerTitles(state) {
    return state.ManagerTitles;
  },
  getCompanyReportTypes(state) {
    return state.ReportTypes;
  },
  getCompanySections(state) {
    return state.Sections;
  },
  getCompanyReport(state) {
    return state.Report;
  },
  getCompanyWorkHours(state) {
    return state.WorkHours;
  },
};
const mutations = {
  setReportHasSearch(state, payload) {
    state.isReportHasSearch = payload;
  },
  saveHoliday(state, payload) {
    state.Holidays = state.Holidays.filter((p) => p.ID !== payload.DTO.ID);
    state.Holidays.push(payload.DTO);
  },
  deleteHoliday(state, payload) {
    state.Holidays = state.Holidays.filter((p) => p.ID !== payload.ID);
  },
  getHoliday(state, payload) {
    state.Holiday = payload.DTO;
  },
  setHoliday(state, payload) {
    state.Holiday = payload;
  },

  getHolidays(state, payload) {
    state.Holidays = payload;
  },
  getWorkHours(state, payload) {
    state.WorkHours = payload;
  },
  getTitles(state, payload) {},
  saveTitle(state, payload) {},
  deleteTitle(state, payload) {},
  getTitle(state, payload) {},
  saveWorkHours(state, payload) {},
  saveOrganization(state, payload) {},
  addManagerTitle(state, payload) {
    state.ManagerTitles.push(payload);
  },
  saveReport(state, payload) {
    state.Reports = state.Reports.filter((p) => p.ID !== payload.DTO.ID);
    state.Reports.push(payload.DTO);
  },
  deleteReport(state, payload) {
    state.Reports = state.Reports.filter((p) => p.ID !== payload.ID);
  },
  getReport(state, payload) {
    state.Report = payload.DTO;
    state.Report.To = state.Report.To ? state.Report.To.split(';') : [];
    state.Report.Cc = state.Report.Cc ? state.Report.Cc.split(';') : [];
    state.Report.Bcc = state.Report.Bcc ? state.Report.Bcc.split(';') : [];
    state.Report.Type = `${state.Report.Type}`;
    state.Report.SectionId = payload.DTO.SectionId;
  },
  setReport(state, payload) {
    if (!payload) {
      state.Report = null;
      return;
    }
    state.Report = {};
    Object.keys(payload).forEach((key) => {
      state.Report[key] = payload[key];
    });
  },
  getReports(state, payload) {
    state.Reports = payload.Items;
    state.Sections = payload.SectionList.map((p) => ({ id: p.ID, text: p.Name }));
    state.ReportTypes = payload.ReportTypes.map((p) => ({ id: p.ID, text: p.Name }));
  },

  getOrganization(state, payload) {
    state.Organization = payload.Nodes;
    state.Members = payload.Members.map((p) => ({ id: p.ID, text: p.Name }));
    state.ManagerTitles = payload.Titles.map((p) => ({ id: p.ID, text: p.Name }));
  },
};

const actions = {
  saveHoliday(context, payload) {
    let request = payload;
    CompanyService.saveHoliday(request)
      .then((res) => {
        context.commit('saveHoliday', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  addManagerTitle(context, payload) {
    let request = payload;
    context.commit('addManagerTitle', request);
  },
  deleteHoliday(context, payload) {
    let request = payload;
    CompanyService.deleteHoliday(request)
      .then((res) => {
        context.commit('deleteHoliday', request);
      })
      .catch((err) => console.log('catch ', err));
  },
  getHoliday(context, payload) {
    let request = payload;
    CompanyService.getHoliday(request)
      .then((res) => {
        context.commit('getHoliday', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  setHoliday(context, payload) {
    context.commit('setHoliday', payload);
  },
  getHolidays(context) {
    CompanyService.getHolidays()
      .then((res) => {
        context.commit('getHolidays', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  getWorkHours(context, payload) {
    let request = payload;
    CompanyService.getWorkHours()
      .then((res) => {
        context.commit('getWorkHours', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  getTitles(context, payload) {
    let request = payload;
    CompanyService.getTitles(request)
      .then((res) => {
        context.commit('getTitles', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  saveTitle(context, payload) {
    let request = payload;
    CompanyService.saveTitle(request)
      .then((res) => {
        context.commit('saveTitle', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  deleteTitle(context, payload) {
    let request = payload;
    CompanyService.deleteTitle(request)
      .then((res) => {
        context.commit('deleteTitle', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  getTitle(context, payload) {
    let request = payload;
    CompanyService.getTitle(request)
      .then((res) => {
        context.commit('getTitle', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  saveWorkHours(context, payload) {
    let request = payload;
    CompanyService.saveWorkHours(request)
      .then((res) => {
        context.commit('saveWorkHours', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  saveOrganization(context, payload) {
    let request = payload;
    CompanyService.saveOrganization(request)
      .then((res) => {
        context.commit('saveOrganization', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  saveReport(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      CompanyService.saveReport(request)
        .then((res) => {
          context.commit('saveReport', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  deleteReport(context, payload) {
    let request = payload;
    CompanyService.deleteReport(request)
      .then((res) => {
        context.commit('deleteReport', request);
      })
      .catch((err) => console.log('catch ', err));
  },
  getReport(context, payload) {
    let request = payload;
    CompanyService.getReport(request)
      .then((res) => {
        context.commit('getReport', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  setReport(context, payload) {
    context.commit('setReport', payload);
  },
  getReports(context, payload) {
    let request = payload;
    CompanyService.getReports(request)
      .then((res) => {
        context.commit('getReports', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },

  getOrganization(context, payload) {
    let request = payload;
    CompanyService.getOrganization(request)
      .then((res) => {
        context.commit('getOrganization', res.data);
      })
      .catch((err) => console.log('catch ', err));
  },
  fetchReportHasSearch({ commit }, data) {
    commit('setReportHasSearch', data);
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
