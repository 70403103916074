<template>
	<div class="data-table-export-data">
		<span>{{ downloadText }}</span>
		<select class="form-control" v-model="selectedExport">
			<option
				v-for="(exportType, i) in allowedExports"
				:key="i"
				:value="exportType">
				{{ exportType }}
			</option>
		</select>
		<button class="btn btn-primary" @click="download()">
			{{ downloadButtonText }}
		</button>
	</div>
</template>
<script>
import exportFromJSON from "export-from-json";

export default {
	name: "DataTableExportButton",
	data() {
		return {
			selectedExport: ""
		};
	},
	methods: {
		download() {
			exportFromJSON({
				data: this.data,
				fileName: this.downloadFileName,
				exportType: this.selectedExport
			});
		}
	},
	props: {
		data: Array,
		allowedExports: Array,
		downloadButtonText: String,
		downloadFileName: String,
		downloadText: String
	},
	watch: {
		allowedExports: {
			handler(value) {
				this.selectedExport = value[0];
			},
			immediate: true
		}
	}
};

</script>
<style lang="scss">
.data-table-export-data {
	display: flex;
	align-items: center;
	padding-left: 4px;
	grid-area: download;
	select {
		margin: 0 6px;
		max-width: 70px;
	}
	span {
		white-space: nowrap;
	}
}

</style>
