import { reactive } from 'vue';
import CategoryService from '@/services/category.service';
import axios from 'axios';
import authHeader from '@/services/auth-header';
const API_URL = process.env.VUE_APP_API_URL;
// const state = reactive({
//   testData: '#e91e63',
// });
const state = {
  testData: '#e91e63',
};

const getters = {
  getTestData(state) {
    return state.testData;
  },
};

const mutations = {
  setMutateHandler(state, input) {
    let data = input.data;
    let type = input.type;
    state[type] = data;
  },
};

const actions = {
  async setTestData(context, data) {
    return await new Promise(function (resolve, reject) {
      axios.get('https://dummyjson.com/products/1').then((res) => {
        let input = {
          data: res.data,
          type: 'testData',
        };
        context.commit('setMutateHandler', input);
        resolve(res.data);
      });
    });
  },
  async setTestData2({ commit }) {
    return await new Promise(function (resolve, reject) {
      CategoryService.getAllocations()
        .then((res) => {
          let input = {
            data: res.data,
            type: 'testData',
          };
          commit('setMutateHandler', input);
          resolve();
        })
        .catch((err) => console.log('catch ', err));
    });
  },
  // async setTestData3({ commit }) {
  //   await axios.get(`${API_URL}/webapi/category/allocations`, { headers: authHeader }).then((res) => {
  //   const header = authHeader();
  //   await axios.get(`${API_URL}/webapi/category/allocations`, { headers: authHeader }).then((res) => {
  //     let input = {
  //       data: res.data,
  //       type: 'testData',
  //     };
  //     commit('setMutateHandler', input);
  //   });
  // },
  // setAhmet({ commit }, data) {
  //   let input = {
  //     data: data,
  //     type: 'testData',
  //   };
  //   commit('setMutateHandler', input);
  // },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
