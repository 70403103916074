import axios from 'axios';
import AuthHeader from './auth-header';
const { authHeader } = AuthHeader();
const API_URL = process.env.VUE_APP_API_URL;

class AccountService {
  confirmAccount(request) {
    return axios.post(`${API_URL}/webapi/account/confirm`, request);
  }
  getPromotion(request) {
    return axios.post(`${API_URL}/webapi/account/promotion`, request);
  }
  forgotAccount(request) {
    return axios.post(`${API_URL}/webapi/account/forgot`, request);
  }
}
export default new AccountService();
