const state = {
  tableInput: '',
};

const getters = {
  getTableInput(state) {
    return state.tableInput;
  },
};

const mutations = {
  setMutateHandler(state, input) {
    let data = input.data;
    let type = input.type;
    //console.log(input);
    state[type] = data;
  },
};

const actions = {
  tableInputAction({ commit }, data) {
    let input = {
      data: data,
      type: 'tableInput',
    };
    commit('setMutateHandler', input);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
