const state = {
  isTagDropdownClicked: false,
  isTagDropdownOpen: false,

  isDescriptionDropdownClicked:false,
  isDescriptionDropdownOpen:false,
};

const getters = {
  getTagDropdownClicked(state) {
    return state.isTagDropdownClicked;
  },
  getTagDropdownOpen(state) {
    return state.isTagDropdownOpen;
  },
  getDescriptionDropdownClicked(state) {
    return state.isDescriptionDropdownClicked;
  },
  getDescriptionDropdownOpen(state) {
    return state.isDescriptionDropdownOpen;
  },
};

const mutations = { 
  setMutateHandler(state, input) {
    let data = input.data;
    let type = input.type;
    state[type] = data;
  },
};

const actions = {
  clickTagDropdownAction({ commit }, data) {
    let input = {
      data: data,
      type: "isTagDropdownClicked",
    };
    commit("setMutateHandler", input);
  },
  openTagDropdownAction({ commit }, data) {
    let input = {
      data: data,
      type: "isTagDropdownOpen",
    };
    commit("setMutateHandler", input);
  },
  clickDescriptionDropdownAction({ commit }, data) {
    let input = {
      data: data,
      type: "isDescriptionDropdownClicked",
    };
    commit("setMutateHandler", input);
  },
  openDescriptionDropdownAction({ commit }, data) {
    let input = {
      data: data,
      type: "isDescriptionDropdownOpen",
    };
    commit("setMutateHandler", input);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
