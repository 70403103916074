import AccountService from '@/services/account.service';
import { reactive } from 'vue';
const state = reactive({});
const getters = {};
const mutations = {
  resetAccount(state, payload) {},
  confirmAccount(state, payload) {},
  forgotAccount(state, payload) {},
};
const actions = {
  confirmAccount(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      AccountService.confirmAccount(request)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  getPromotion(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      AccountService.getPromotion(request)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
  forgotAccount(context, payload) {
    return new Promise(function (resolve, reject) {
      let request = payload;
      AccountService.forgotAccount(request)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          console.log('catch ', err);
          return reject(err);
        });
    });
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
