import axios from 'axios';
import AuthHeader from './auth-header';
const { authHeader } = AuthHeader();
const API_URL = process.env.VUE_APP_API_URL;

class ClockService {
  getAlerts() {
    const header = authHeader();
    return axios.get(`${API_URL}/webapi/clock/alerts`, { headers: header });
  }
  getSection(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/clock/section`, request, { headers: header });
  }
  getEmployee(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/clock/employee`, request, { headers: header });
  }
  saveManual(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/clock/manual/save`, request, { headers: header });
  }
  getManual(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/clock/manual`, request, { headers: header });
  }
  deleteManual(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/clock/manual/delete`, request, { headers: header });
  }
  resendInvitation(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/clock/invitation/resend`, request, { headers: header });
  }
  cancelInvitation(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/clock/invitation/delete`, request, { headers: header });
  }
  saveInvitation(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/setting/invitation/save`, request, {
      headers: header,
    });
  }
  saveWebClock(request) {
    const header = authHeader();
    return axios.post(`${API_URL}/webapi/clock/web/save`, request, { headers: header });
  }
}

export default new ClockService();
