const state = {
  dialogState: false,
  youtubeModal: false,
  onboardingModal: false,
  confirmModal: false,
  timeSheetModal: false,
  confirmModalKey: '',
};

const getters = {
  getDialogState(state) {
    return state.dialogState;
  },
  getYoutubeModalState(state) {
    return state.youtubeModal;
  },
  getConfirmModalState(state) {
    return state.confirmModal;
  },
  getOnboardingModalState(state) {
    return state.onboardingModal;
  },
  getTimeSheetgModalState(state) {
    return state.timeSheetModal;
  },
  getConfirmModalKey(state) {
    return state.confirmModalKey;
  },
};

const mutations = {
  setMutateHandler(state, input) {
    let data = input.data;
    let type = input.type;
    state[type] = data;
  },
  setModalState(state, data) {
    state[data.modal] = data.value;
  },
  setAllClose(state) {
    for (const data in state) {
      state[data] = false;
    }
  },
};

const actions = {
  setDialogStateAction({ commit }, data) {
    let input = {
      data: data,
      type: 'dialogState',
    };
    commit('setMutateHandler', input);
  },
  setModalStateAction({ commit }, data) {
    commit('setModalState', data);
  },
  closeAllModals({ commit }) {
    commit('setAllClose');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
