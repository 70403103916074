<template>
  <td colspan="1">
    <div class="profile">
      <div class="profile-img-wrapper">
        <div class="text-wrapper">
          <span class="question">?</span>
        </div>
      </div>

      <span class="name">{{ data.Email }}</span>
    </div>
  </td>
  <td v-for="(i, index) in calculateColspanCount.primary" :key="index" colspan="1">&nbsp;</td>
  <td style="text-align: left" colspan="3">
    <div class="waiting-invitation">
      <span>{{ t('Clock.InvitationSentLabel') }}</span>
      <fl-icon class="fl-4x fl-retry dark-blue exit" @click="onResendClick" />
      <fl-icon class="fl-5x fl-exit-rounded red exit" @click="openConfirmDialog" />
    </div>
  </td>
  <template v-if="calculateColspanCount.secondary">
    <td v-for="(i, index) in calculateColspanCount.secondary" :key="index" colspan="1">&nbsp;</td>
  </template>
  <ConfirmDialog @cancel="onCancel" @accept="onAccept" />
</template>

<script setup>
import ConfirmDialog from '@/components/confirmDialog/ConfirmDialog.vue';
import { ref, defineProps, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useNotification } from '@kyvg/vue3-notification';
import { useI18n } from 'vue-i18n';
const { notify } = useNotification();
const { t } = useI18n({ useScope: 'global' });
const store = useStore();
const route = useRoute();
const props = defineProps({
  columnCount: {
    type: Number,
    required: true,
  },
  data: {
    type: Object,
  },
});
const secondaryColspanCount = ref(0);
const isClock = () => {
  return route.path.includes('clock/');
};
// const calculateColspanCount = computed(() => props.columnCount - 3);
const calculateColspanCount = computed(() => {
  switch (props.columnCount) {
    case 5:
      return {
        primary: props.columnCount - 4,
        secondary: 2,
      };
    case 6:
      return {
        primary: props.columnCount - 4,
        secondary: 2,
      };
    case 7:
      return {
        primary: props.columnCount - 3,
        secondary: null,
      };
    case 8:
      return {
        primary: props.columnCount - 4,
        secondary: 1,
      };
    case 9:
      return {
        primary: props.columnCount - 5,
        secondary: 1,
      };
    default:
      return {
        primary: props.columnCount - 3,
        secondary: null,
      };
  }
});
const onResendClick = () => {
  store.dispatch('resendInvitation', { Email: props.data.Email });
  notify({
    type: 'success',
    title: t('Notification.ResendInvitationTitle'),
    text: t('Notification.ResendInvitationDescription'),
  });
};
const onCancelClick = () => {
  if (isClock()) store.dispatch('cancelInvitation', { Email: props.data.Email });
  else store.dispatch('cancelEmployeeInvitation', { Email: props.data.Email });
};
const openConfirmDialog = () => {
  store.dispatch('setModalStateAction', {
    modal: 'confirmModal',
    value: true,
  });
};
const onAccept = () => {
  console.log('accept');
  onCancelClick();
};
const onCancel = () => {
  console.log('onCancel');
};
</script>

<style scoped lang="scss">
.waiting-invitation {
  display: flex;
  align-items: center;
  span {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #79797a;
  }
  .reload {
    height: 12px;
    margin-left: 15px;
    cursor: pointer;
  }
  .exit {
    cursor: pointer;
  }
}
.profile {
  display: flex;
  align-items: center;
  .profile-img-wrapper {
    background: #c4c4c4;
    border-radius: 11px;
    width: fit-content;
    margin-right: 10px;
    .question {
      color: white;
      position: relative;
      z-index: 2;
      padding: 4px 9px;
    }
  }
  .name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #79797a;
  }
}
.text-wrapper {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c4c4c4;
  border-radius: 11px;
  // width: fit-content;
  color: white;
  position: relative;
  padding: 7px 7px;
  span {
    padding: 4px;
  }
}

tr td:first-child {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
tr td:last-child {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
td {
  text-align: left;
  padding: 18px;
  max-width: 177px;
}
</style>
